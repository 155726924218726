import { Controller, FieldError, FieldValues, UseControllerProps } from 'react-hook-form'
import { BoxProps, TextField } from '@mui/material'

type Props<TFieldValues extends FieldValues> = UseControllerProps<TFieldValues> & {
	label: string
	min?: number
	max?: number
	defaultValue: string
	sx?: BoxProps['sx']
	error?: FieldError
	disabled?: boolean
}
export function MyNumberInput<TFieldValues extends FieldValues>({
	name,
	control,
	label,
	rules,
	error,
	sx,
	min,
	max,
	defaultValue,
}: Props<TFieldValues>) {
	if (min && max && min > max) {
		throw new Error('min should be less than max')
	}
	const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): string | number => {
		const value = (e.target as HTMLInputElement).value
		const num = value === '' ? '' : Number(value)
		return num
	}

	const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		const value = (e.target as HTMLInputElement).value
		const num = value === '' ? '' : Number(value)

		if (typeof num === 'number' && !isNaN(num)) {
			if (min !== undefined && num < min) e.target.value = min.toString()
			if (max !== undefined && num > max) e.target.value = max.toString()
		}
	}

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value } }) => (
				<TextField
					value={value.toString()}
					onChange={(e) => onChange(handleNumberChange(e))}
					onBlur={handleBlur}
					type="number"
					margin="dense"
					size={'small'}
					fullWidth
					sx={sx}
					label={label}
					error={!!error}
					helperText={error ? error.message : null}
					inputProps={{
						min,
						max,
						'aria-label': label,
					}}
				/>
			)}
			rules={rules}
		/>
	)
}
