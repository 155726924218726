import { Box, keyframes, Typography } from '@mui/material'
import { COLOR } from '../../layout/constants'

type Props = {
	text?: string
}

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`
export const Loading = ({ text }: Props) => {
	return (
		<Box
			data-testid="loading"
			sx={{
				height: 'calc(100vh - 150px)',
				display: 'flex',
				alignItems: 'center',
				zIndex: '10',
			}}
		>
			<Box
				sx={{
					position: 'relative',
					margin: '0 auto',
					width: '200px',
					height: '200px',
				}}
			>
				{text && (
					<Typography
						component="span"
						sx={{
							fontSize: '14px',
							fontWeight: '600',
							lineHeight: '29.88px',
							textAlign: 'center',
							color: COLOR.white,
							marginBottom: '10px',
							position: 'absolute',
							top: 'calc(50% - 15px)',
							width: '100%',
							zIndex: 1,
						}}
					>
						{text}
					</Typography>
				)}
				<Box
					sx={{
						width: '200px',
						padding: '10px',
						aspectRatio: '1',
						borderRadius: '50%',
						background:
							'radial-gradient(farthest-side,#ffa516 94%,#0000) top/15px 15px no-repeat, conic-gradient(#0000 30%,#ffa516)',
						'--_m': `radial-gradient(farthest-side,#0000 calc(100% - 15px),#000 0)`,
						WebkitMask: 'var(--_m)',
						mask: 'var(--_m)',
						WebkitMaskComposite: 'source-out',
						maskComposite: 'subtract',
						animation: `${rotate} 2s infinite linear`,
					}}
				/>
			</Box>
		</Box>
	)
}
