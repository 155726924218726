import { ComponentType, useEffect } from 'react'
import { MyRole } from '../constants/my-role.ts'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MyPermissions } from '../constants/my-permissions.ts'
import { usePermissions } from '../hooks/use-permissions.ts'
import { Loading } from './Loading'

type Props = {
	Component: ComponentType
	requiredRoles?: MyRole[]
	requiredPermissions?: MyPermissions[]
}

export function AuthorizationGuard({ Component, requiredRoles, requiredPermissions }: Props) {
	const { user, isLoading, error } = useAuth0()
	const { isLoading: loadingPermissions, error: permissionsError, permissions } = usePermissions()
	const navigate = useNavigate()
	const { pathname } = useLocation()

	useEffect(() => {
		if (!isLoading && (!user || error) && !pathname.includes('registration')) {
			navigate('/registration')
		}
	}, [isLoading, error, pathname, navigate, user])

	if (isLoading || loadingPermissions) {
		return <Loading />
	}
	if (permissionsError) {
		return <div>Unauthorized</div>
	}
	const userPermissions = permissions || []
	const userRoles: MyRole[] = user?.['paybotic_banking_roles'] || []
	const hasRequiredRoles = !requiredRoles || requiredRoles.some((requiredRole) => userRoles.includes(requiredRole))
	const hasRequiredPermissions =
		!requiredPermissions ||
		requiredPermissions.every((requiredPermission) => userPermissions.includes(requiredPermission))
	if (hasRequiredRoles && hasRequiredPermissions) {
		return <Component />
	} else {
		return <div>Unauthorized</div>
	}
}
