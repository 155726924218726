import { Box, Button, Divider, Paper, Typography, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { MyDateField } from '../../../components/form-components/my-date-field.tsx'
import { COLOR } from '../../../layout/constants.ts'
import { DataTable } from '../components/Table.tsx'
import { useGenerateReport } from '../../../react-query/hooks/use-generate-report.ts'
import { useReport } from '../../../react-query/hooks/use-report.ts'
import { formatCentsToCurrency } from '../../../utils/money-utils.ts'
import { dayjs } from '../../../utils/dates.ts'
import { useCallback, useMemo } from 'react'
import { MyAutocompleteField } from '../../../components/form-components/my-autocomplete-field.tsx'
import { ProfileItem } from '../components/ProfileItem.tsx'
import { useFacilities } from '../../../react-query/hooks/use-facilities.ts'
import { ReportData } from 'src/@types/merchant'

const formSchema = z.object({
	store: z.string().optional(),
	from: z.string().optional(),
	to: z.string().optional(),
})

const oneMonth = dayjs().subtract(1, 'month').format('YYYY-MM-DDT00:00:00Z')
const today = dayjs().format('YYYY-MM-DDT00:00:00Z')

const defaultValues = {
	store: '',
	from: oneMonth,
	to: today,
}

export function MerchantReportsPage() {
	const methods = useForm({
		defaultValues,
		resolver: zodResolver(formSchema),
	})
	const { watch, control } = methods
	const fromDate = watch('from')
	const toDate = watch('to')
	const storeId = watch('store')

	const reportParams = useMemo(() => {
		return {
			store_id: storeId,
			start_date: dayjs(fromDate).format('YYYY-MM-DDT00:00:00Z'),
			end_date: dayjs(toDate).format('YYYY-MM-DDT00:00:00Z'),
		}
	}, [fromDate, storeId, toDate])

	const facilities = useFacilities()
	const generateReportMutation = useGenerateReport()
	const { data: reportData, isLoading: reportLoading } = useReport(reportParams)

	const generateReport = useCallback(() => {
		generateReportMutation.mutate(reportParams)
	}, [generateReportMutation, reportParams])

	return (
		<>
			<Paper sx={{ p: 4 }}>
				<Typography
					sx={{
						fontSize: 22,
						fontWeight: 600,
						color: COLOR.textColor,
					}}
				>
					Transactions report
				</Typography>
				<Divider
					sx={{
						marginTop: 1,
						marginBottom: 2,
						color: COLOR.textColor,
						borderBottom: `2px solid ${COLOR.textColor}`,
					}}
				/>
				<Box display="flex">
					<FormProvider {...methods}>
						<form style={{ width: '100%' }}>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={6}
									md={3}
								>
									<MyAutocompleteField
										name="store"
										label="Store"
										control={control}
										options={facilities?.data?.map((f) => ({ value: String(f.ID), label: f.FacilityName })) || []}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									md={3}
								>
									<MyDateField
										name="from"
										label="From"
										control={control}
										maxDate={toDate ? dayjs(toDate) : dayjs()}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									md={3}
								>
									<MyDateField
										name="to"
										label="To"
										control={control}
										minDate={dayjs(fromDate)}
									/>
								</Grid>
							</Grid>
						</form>
					</FormProvider>
					<Button
						onClick={generateReport}
						sx={{ background: COLOR.orange, alignSelf: 'center' }}
						variant={'contained'}
						size={'large'}
					>
						EXPORT
					</Button>
				</Box>
			</Paper>
			<Paper sx={{ p: 2, mt: 2 }}>
				<DataTable
					loading={reportLoading}
					data={reportData || []}
					columns={[
						{ label: 'Store name', dataIndex: 'StoreName' },
						{ label: 'Customer Name', dataIndex: 'CustomerName' },
						{
							label: 'Transaction Time',
							dataIndex: 'DateTimeCreated',
							render: (value) => dayjs(value).tz('US/Central').format('MM/DD/YYYY HH:mm:ss z'),
						},
						{
							label: 'Status',
							dataIndex: 'TransactionStatus',
						},
						{
							label: 'Sales Amount',
							dataIndex: 'BaseAmount',
							align: 'right',
							render: (value) => formatCentsToCurrency(value as number),
						},
						{
							label: 'Settlement Amount',
							dataIndex: 'TotalPayment',
							align: 'right',
							render: (value) => formatCentsToCurrency(value as number),
						},
					]}
					expandable={(row: ReportData) => (
						<Box sx={{ display: 'flex', py: 2, gap: 3, justifyContent: 'space-between' }}>
							<ProfileItem
								label="Bank Account"
								value={`****${row.LastFour}`}
							/>
							<ProfileItem
								label="Settlement Time"
								value={dayjs(row.DateTimeOfApproval).tz('US/Central').format('MM/DD/YYYY HH:mm:ss z')}
							/>
							<ProfileItem
								label="Transaction ID"
								value={row.PublicID}
							/>
							<ProfileItem
								label="Fee"
								value={formatCentsToCurrency(row.FeeApprovedAmount as number)}
							/>
						</Box>
					)}
				/>
			</Paper>
		</>
	)
}
