import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'
import { Nullable } from '../../@types/nullable'
import { DashboardData } from '../../@types/merchant'

async function getDashboardData(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('businesses/dashboard')
		return response.json<DashboardData>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useMerchantDashboard() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<DashboardData>, Error, Nullable<DashboardData>>({
		queryKey: ['merchant-dashboard'],
		queryFn: () => getDashboardData(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated,
	})
}
