import { useMutation, useQueryClient } from '@tanstack/react-query'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { KyInstance } from 'ky'
import { useAuth0 } from '@auth0/auth0-react'

function activateAccount(httpInstance: KyInstance) {
	return httpInstance.post('users/activate').json<string>()
}
export function useActivateAccount() {
	const { getAccessTokenSilently } = useAuth0()
	const { paymentServiceClient } = usePaymentService()
	const queryClient = useQueryClient()

	return useMutation<string, Error, void, string>({
		mutationFn: async () => {
			return activateAccount(paymentServiceClient.httpInstance)
		},
		onSuccess: () => {
			getAccessTokenSilently({ cacheMode: 'off' })
			queryClient.invalidateQueries({
				queryKey: ['user'],
			})
		},
	})
}
