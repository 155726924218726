import { ThemeOptions } from '@mui/material/styles'
import { createTheme } from '@mui/material'
import '@fontsource/inter'

export const theme: ThemeOptions = createTheme({
	typography: {
		allVariants: {
			fontFamily: 'Inter',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: 'Roboto',
				},
			},
		},
	},
	palette: {
		background: {
			default: '#5d8bb7',
		},
		mode: 'light',
		primary: {
			main: '#244D74',
		},
		secondary: {
			main: '#F5861F',
			contrastText: '#fff',
			dark: '#ce3d05',
		},
		info: {
			main: 'rgb(79, 112, 143)',
		},
		warning: {
			main: '#e53935',
		},
	},
	shape: {
		borderRadius: 4,
	},
	spacing: 8,
})
