import { z } from 'zod'
import { BusinessUser, isUserOrCreatedBy } from '../../merchant/models/business-user.models.ts'
import { Box, TableCell, TableRow } from '@mui/material'
import { RoleSelect } from './role-select.tsx'
import { useForm } from 'react-hook-form'
import { DeleteBusinessUserButton } from './delete-bu-button.tsx'
import { canEditPassword, getNameFromAuthRoles } from '../../../constants/my-role.ts'
import { LinkButton } from 'src/components/link-button.tsx'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/CheckBoxRounded'
import { MyTextField } from 'src/components/form-components/my-text-field.tsx'
import { useUpdateBURole } from 'src/react-query/hooks/use-user-update-role.ts'
import { useUpdateBUPassword } from 'src/react-query/hooks/use-user-set-password.ts'

const INITIAL_PASSWORD = '********'

const roleSchema = z.object({
	role: z.string().min(1, { message: 'Role required' }),
})

type RoleType = z.infer<typeof roleSchema>

type BusinessUserRowProps = {
	row: BusinessUser
	businessUserAuth0Id: string
	businessUserCreatedBy?: string
	businessUserRole: string
	allowEdit: boolean
	allowDelete: boolean
	onEdit: (id: string) => void
	onCancel: () => void
	editingId: string | undefined
}

export const BusinessUserRow = ({
	row,
	businessUserAuth0Id,
	businessUserCreatedBy,
	businessUserRole,
	allowEdit,
	allowDelete,
	onEdit,
	onCancel,
	editingId,
}: BusinessUserRowProps) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<RoleType>()

	const {
		control: controlPassword,
		formState: { errors: errorsPassword },
		setValue: setValuePassword,
		watch: watchPassword,
	} = useForm<{ password: string }>({
		defaultValues: {
			password: INITIAL_PASSWORD,
		},
	})

	const setPassword = (password: string) => setValuePassword('password', password)
	const password = watchPassword('password') || ''

	const role = getNameFromAuthRoles(row.Roles)

	if (isUserOrCreatedBy({ auth0ID: row.Auth0ID, businessUserAuth0Id, businessUserCreatedBy })) {
		allowEdit = false
		allowDelete = false
	}

	const setInitialState = () => {
		setPassword(INITIAL_PASSWORD)
		onCancel()
	}

	const handleRoleFormSubmit = async (roleType: RoleType) =>
		updateBURole({ buAuth0Id: row.Auth0ID, role: roleType.role })

	const handlePasswordFormSubmit = async (password: string) => updateBUPassword({ buAuth0Id: row.Auth0ID, password })

	const { mutate: updateBURole, isPending: isLoadingRole } = useUpdateBURole()

	const { mutate: updateBUPassword, isPending: isLoadingPassword } = useUpdateBUPassword(setInitialState)

	const isLoading = isLoadingRole || isLoadingPassword

	const isEditing = editingId === row.Auth0ID

	const roleCanEditPassword = canEditPassword(businessUserRole, role)

	const canEdit = roleCanEditPassword && !editingId && allowEdit && !isLoading

	const disableSavePassword = !password.trim().length || password === INITIAL_PASSWORD || isLoading

	const disableCancel = isLoading

	const baseTextSx = {
		fontSize: {
			xs: '0.75rem',
			sm: '1rem',
			md: '1rem',
		},
	}

	return (
		<TableRow>
			<TableCell sx={baseTextSx}>{row.Username}</TableCell>
			<TableCell sx={baseTextSx}>{row.Email}</TableCell>
			<TableCell sx={{ ...baseTextSx, minWidth: isEditing ? '200px' : undefined }}>
				<Box
					display="inline-flex"
					justifyContent="center"
				>
					{isEditing ? (
						<MyTextField
							type="password"
							error={errorsPassword.password}
							name="password"
							control={controlPassword}
							defaultValue={INITIAL_PASSWORD}
							rules={{ required: 'Password required' }}
							onFocus={() => password === INITIAL_PASSWORD && setPassword('')}
							onBlur={() => !password && setPassword(INITIAL_PASSWORD)}
							variant="standard"
						/>
					) : (
						<Box sx={{ paddingRight: 2 }}>{INITIAL_PASSWORD}</Box>
					)}
					{(!editingId || (editingId && !isEditing)) && (
						<LinkButton
							handleClick={() => onEdit(row.Auth0ID)}
							sx={{ minWidth: '20px', paddingRight: 1 }}
							disabled={!canEdit}
						>
							<EditIcon color={canEdit ? 'error' : 'disabled'} />
						</LinkButton>
					)}
					{isEditing && (
						<Box
							display="flex"
							alignItems="center"
							sx={{ paddingRight: 1, paddingLeft: 2 }}
						>
							<LinkButton
								disabled={disableSavePassword}
								handleClick={() => {
									if (password !== INITIAL_PASSWORD) {
										handlePasswordFormSubmit(password)
									} else {
										setInitialState()
									}
								}}
								sx={{ minWidth: '20px' }}
							>
								<CheckIcon color={!disableSavePassword ? 'success' : 'disabled'} />
							</LinkButton>
							<LinkButton
								handleClick={() => {
									setInitialState()
								}}
								disabled={disableCancel}
								sx={{ minWidth: 0 }}
							>
								<CancelIcon color={!disableCancel ? 'error' : 'disabled'} />
							</LinkButton>
						</Box>
					)}
				</Box>
			</TableCell>
			<TableCell>
				<form onSubmit={handleSubmit(handleRoleFormSubmit)}>
					<RoleSelect
						name="role"
						control={control}
						defaultValue={role}
						error={errors.role}
						handleChange={() => handleSubmit(handleRoleFormSubmit)()}
						labelEnabled={false}
						disabled={!allowEdit || !!editingId}
						sx={baseTextSx}
					/>
				</form>
			</TableCell>
			<TableCell>
				<Box
					display="flex"
					justifyContent="flex-end"
				>
					<DeleteBusinessUserButton
						businessUserId={row.ID}
						username={row.Username}
						allowDelete={allowDelete && !editingId}
					/>
				</Box>
			</TableCell>
		</TableRow>
	)
}
