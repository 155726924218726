import { Box, Button, Paper, Typography } from '@mui/material'
import { useAppStore } from '../../../../store/appStore.ts'
import { useNavigate } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import { useEffect } from 'react'

export function MerchantCheckoutFail() {
	const navigate = useNavigate()
	const purchaseError = useAppStore((state) => state.purchaseError)

	useEffect(() => {
		if (!purchaseError) {
			navigate('/merchant/checkout')
		}
	}, [])

	return (
		<Box
			mt={2}
			sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}
		>
			<Paper sx={{ p: 4 }}>
				<Box
					display={'flex'}
					flexDirection="column"
					alignItems={'center'}
				>
					<Typography
						variant="h6"
						color="error"
						gutterBottom
					>
						Payment Failed
					</Typography>
					<WarningIcon
						color="error"
						sx={{ fontSize: 60 }}
					/>
					<Typography
						color="error"
						gutterBottom
					>
						{purchaseError ?? 'An error occurred'}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigate('/merchant/checkout')}
						fullWidth={true}
						data-testid="checkout-fail-try-again-button"
						sx={{ mt: 2, background: '#CDD7E1', color: '#d32f2f', maxWidth: '300px' }}
					>
						Try Again
					</Button>
				</Box>
			</Paper>
		</Box>
	)
}
