import { z } from 'zod'
import { validatePassword } from '../../../validations/auth0-password-validation.ts'

export const BusinessUser = z.object({
	email: z.string().email({ message: 'Invalid email address' }),
	username: z.string().min(1, { message: 'Username required' }).max(15, { message: '15 character max' }),
	password: z.string().refine(validatePassword, {
		message:
			'Password must be at least 8 characters, contain at least one lowercase letter, one uppercase letter, and one number or special character',
	}),
	role: z.string().min(1, { message: 'Role required' }),
})

export type BusinessUserType = z.infer<typeof BusinessUser>
