import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Nullable } from '../../@types/nullable'
import { HTTPError, KyInstance } from 'ky'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

export type Transaction = {
	id: string
	customer_account_id: number
	merchant_account_id: number
	amount: number
	description: string
	status: string
	expiration: string
	purchase_date: string
	public_id: string
	business_name: string
	last_four: string
	created_at: string
	updated_at: string
}

type TransactionResponse = {
	items: Transaction[]
	page_info: {
		page: number
		page_size: number
		total: number
		has_more: boolean
	}
}

type BodyRequest = {
	limit: number
	offset: number
}

async function getTransaction(): Promise<Transaction> {
	return {
		id: '23045879',
		status: 'Failed',
		amount: 1145,
		description: 'Payment for iPhone 12',
		customer_account_id: 1,
		merchant_account_id: 2,
		expiration: '2022-12-31',
		purchase_date: '2022-10-31',
		public_id: 'idk',
		business_name: 'why does this exist?',
		last_four: '5655',
		created_at: '2022-10-31',
		updated_at: '2022-10-31',
	}
}
export function useTrasaction(transactionId: number) {
	return useQuery<Nullable<Transaction>, Error, Nullable<Transaction>>({
		queryKey: ['transaction', transactionId],
		queryFn: () => getTransaction(),
		enabled: true,
	})
}

async function getTransactions(httpInstance: KyInstance, body: BodyRequest): Promise<TransactionResponse> {
	try {
		const response = await httpInstance.post('purchases/recent', {
			json: body,
		})
		const data = await response.json<TransactionResponse>()
		if (!data) {
			throw new Error('No data returned')
		}
		return data
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			throw new Error('No transactions found')
		}
		throw e
	}
}

export function useTransactions(limit = 3, offset = 0) {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()

	return useQuery<Nullable<TransactionResponse>, Error, Nullable<TransactionResponse>>({
		queryKey: ['transactions', limit, offset],
		queryFn: () => getTransactions(paymentServiceClient.httpInstance, { limit, offset }),
		enabled: !isLoading && isAuthenticated,
		retry: 2,
	})
}

export function useInfiniteTransactions() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	const pageSize = 4

	return useInfiniteQuery<TransactionResponse, Error>({
		queryKey: ['transactions'],
		queryFn: ({ pageParam = 0 }) =>
			getTransactions(paymentServiceClient.httpInstance, {
				limit: pageSize,
				offset: (pageParam as number) * pageSize,
			}),
		getNextPageParam: (lastPage) => {
			const morePagesExist = lastPage.page_info.has_more
			return morePagesExist ? lastPage.page_info.page : undefined
		},
		enabled: !isLoading && isAuthenticated,
		retry: 2,
		initialPageParam: 0,
	})
}
