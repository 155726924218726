import { useSnackbar } from 'notistack'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export type SnackbarVariant = 'error' | 'default' | 'success' | 'warning' | 'info' | undefined

export type SnackbarTrigger = (message: string, variant?: SnackbarVariant) => void

export const useAlert = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const triggerSnackbar: SnackbarTrigger = (message = '', variant: SnackbarVariant = 'error') => {
		enqueueSnackbar(message, {
			variant: variant,
			autoHideDuration: 4000,
			action: (key) => (
				<IconButton
					aria-label="close"
					onClick={() => {
						closeSnackbar(key)
					}}
				>
					<CloseIcon sx={{ color: 'white' }} />
				</IconButton>
			),
		})
	}

	return { triggerSnackbar }
}
