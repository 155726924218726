import { MyTextField } from '../form-components/my-text-field.tsx'
import { Controller, useFormContext } from 'react-hook-form'
import { User } from '../../models/user.models.ts'
import { MyAutocompleteField } from '../form-components/my-autocomplete-field.tsx'
import { usStates } from '../../utils/us-states.ts'
import { TextField } from '@mui/material'

export function StepTwo() {
	const {
		control,
		formState: { errors },
	} = useFormContext<User>()
	return (
		<div>
			<MyTextField
				name="address.street_one"
				label="Street Address"
				error={errors.address?.street_one}
				control={control}
				defaultValue=""
				rules={{ required: 'Street address required' }}
			/>
			<MyTextField
				name="address.street_two"
				label="Address Line 2"
				error={errors.address?.street_two}
				control={control}
				defaultValue=""
			/>
			<MyTextField
				name="address.city"
				label="City"
				error={errors.address?.city}
				control={control}
				defaultValue=""
				rules={{ required: 'City required' }}
			/>
			<MyAutocompleteField
				name="address.state"
				label="State/Territory"
				error={errors.address?.state}
				control={control}
				defaultValue=""
				rules={{ required: 'State required', maxLength: { value: 2, message: 'Only input abbreviation' } }}
				options={usStates}
			/>

			<Controller
				name="address.postal_code"
				control={control}
				defaultValue=""
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<TextField
						label="ZIP Code"
						variant="outlined"
						value={value}
						onChange={(e) => {
							e.preventDefault()
							if (e.target.value.length <= 5) {
								onChange(e.target.value)
							}
						}}
						margin="dense"
						size="small"
						error={!!error}
						helperText={error ? error.message : null}
						inputProps={{ maxLength: 11 }}
						fullWidth
					/>
				)}
				rules={{
					required: 'Zip code required',
					minLength: { value: 5, message: 'Zip code must be 5 digits' },
					maxLength: { value: 5, message: 'Zip code must be 5 digits' },
				}}
			/>
		</div>
	)
}
