import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'
import { Nullable } from '../../@types/nullable'
import { Account } from '../../@types/accounts'

async function getAccounts(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('external_accounts/accounts')
		return response.json<Account[]>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}
export function useAccounts() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<Account[]>, Error, Nullable<Account[]>>({
		queryKey: ['accounts'],
		queryFn: () => getAccounts(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated,
	})
}
