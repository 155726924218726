import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'

import { useMemo } from 'react'

export const useShowHeader = () => {
	const { isAuthenticated } = useAuth0()
	const location = useLocation()

	return useMemo(() => {
		if (location.pathname.includes('/merchant')) {
			return false
		}

		if (isAuthenticated) {
			return true
		}

		return location.pathname.includes('/exception')
	}, [isAuthenticated, location.pathname])
}
