import { Box, Button, Grid } from '@mui/material'
import { DataCard } from '../components/DataCard.tsx' // Grid version 2
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { COLOR } from '../../../layout/constants.ts'
import { useAuthorization } from '../../../hooks/use-authorization.ts'
import { useMerchantDashboard } from '../../../react-query/hooks/use-merchant-dashboard.ts'
import { formatCentsToCurrency } from '../../../utils/money-utils.ts'
import { useNavigate } from 'react-router-dom'
import { MyPermissions } from '../../../constants/my-permissions.ts'

export function MerchantHomePage() {
	const navigate = useNavigate()
	const { isLoading: checkoutLoading, isAuthorized: canCheckout } = useAuthorization(undefined, [
		MyPermissions.CreateACHDebit,
	])
	const { isLoading: dashboardLoading, data: dashboardData } = useMerchantDashboard()

	return (
		<>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
					md={6}
				>
					<DataCard
						loading={dashboardLoading}
						data-testid="total-monthly-sales"
						icon={<AttachMoneyIcon />}
						title={'Total Sales Month-To-Date'}
						label={formatCentsToCurrency(dashboardData?.MonthlySales)}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
				>
					<DataCard
						loading={dashboardLoading}
						data-testid="total-transactions"
						icon={<CreditCardIcon />}
						title={'Total Transactions Month-To-Date'}
						label={`${dashboardData?.MonthlyTotalTransactions || 0}`}
					/>
				</Grid>
			</Grid>
			{canCheckout && !checkoutLoading ? (
				<Box
					display={'flex'}
					justifyContent={'flex-end'}
					mt={2}
				>
					<Button
						onClick={() => navigate('/merchant/checkout')}
						sx={{ minWidth: 300, minHeight: 60, backgroundColor: COLOR.orange }}
						variant={'contained'}
						size={'large'}
					>
						CHECKOUT
					</Button>
				</Box>
			) : null}
		</>
	)
}
