import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { DashboardLayout } from '../components/dashboard-components/dashboard-layout.tsx'
import { COLOR } from '../layout/constants.ts'
import { Loading } from '../components/Loading'
import { useAccounts } from 'src/react-query/hooks/use-accounts.ts'
import { useDeactivateAccount } from 'src/react-query/hooks/use-deactivate-account.ts'
import { useAlert } from 'src/lib/useAlert.tsx'
import { useState } from 'react'
import { getLogoutOptions } from 'src/utils/logout-utils.ts'
import { useSupport } from 'src/react-query/hooks/use-support.ts'
import { FormProvider, useForm } from 'react-hook-form'
import { Support } from 'src/@types/support'
import { zodResolver } from '@hookform/resolvers/zod'
import { MyTextField } from 'src/components/form-components/my-text-field.tsx'

const supportFormSchema = z.object({
	notes: z.string().min(10, 'We need more information'),
})

type ConsumerSupport = Pick<Support, 'notes'>

export function SettingsPage() {
	const { logout, isLoading } = useAuth0()
	const [open, setOpen] = useState(false)
	const { data, isLoading: isLoadingAccounts } = useAccounts()
	const deactivateAccountMutation = useDeactivateAccount()
	const { triggerSnackbar } = useAlert()

	const navigate = useNavigate()

	const methods = useForm<ConsumerSupport>({
		resolver: zodResolver(supportFormSchema),
	})
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = methods

	const onSuccess = () => {
		methods.reset({
			notes: '',
		})
	}
	const supportMutation = useSupport(onSuccess)

	if (isLoading || isLoadingAccounts || !Array.isArray(data)) {
		return <Loading />
	}

	const onClickDeactivateAccount = () => {
		const pendingAmount: number = data.reduce((sum, account) => sum + account.PendingAmount, 0)

		if (pendingAmount > 0) {
			triggerSnackbar('Please wait until your transactions are posted and try again', 'error')
			return
		}

		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const onConfirmDeactivateAccount = () => {
		deactivateAccountMutation.mutate()
		handleClose()
	}

	const onSubmit = async (data: ConsumerSupport) => {
		supportMutation.mutate(data)
	}

	return (
		<DashboardLayout>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<DialogContentText
						id="alert-dialog-description"
						data-testid="alert-deactivate-account"
					>
						Are you sure you would like to deactivate your account?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						data-testid="alert-deactivate-account-button-no"
					>
						No
					</Button>
					<Button
						color={'error'}
						variant={'outlined'}
						onClick={onConfirmDeactivateAccount}
						autoFocus
						data-testid="alert-deactivate-account-button-yes"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
			<Typography
				variant="h4"
				fontWeight="bold"
				sx={{ color: '#244D74', mb: 2, fontSize: '17px', textAlign: 'center' }}
			>
				Settings
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				gap={4}
				mb={4}
			>
				<Button
					onClick={() => navigate('/dashboard/settings/personal-details')}
					sx={{ height: '56px' }}
					size="large"
					variant="contained"
				>
					PERSONAL DETAILS
				</Button>
				<Button
					sx={{ height: '56px' }}
					size="large"
					variant="contained"
					disabled={deactivateAccountMutation.isPending}
					onClick={onClickDeactivateAccount}
					data-testid="button-deactivate-account"
				>
					DEACTIVATE ACCOUNT
				</Button>
			</Box>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Box
						display="flex"
						flexDirection="column"
					>
						<Typography
							mb={1}
							component="label"
							sx={{ color: COLOR.successText }}
						>
							Need support! Fill out this form below
						</Typography>

						<MyTextField
							name="notes"
							placeholder="Help"
							error={errors.notes}
							control={control}
							background="white"
							rules={{ required: 'Notes required' }}
							multiline={true}
							rows={4}
						/>
						<Typography
							mt={1}
							component="a"
							sx={{ color: COLOR.successText, textDecoration: 'none' }}
							href="mailto:support@payboticfinancial.com"
						>
							or email: support@payboticfinancial.com
						</Typography>
					</Box>
					<Box
						mt={2}
						display={'flex'}
						justifyContent={'flex-end'}
					>
						<Button
							onClick={handleSubmit(onSubmit)}
							sx={{
								background: '#FF9100',
							}}
							variant="contained"
							disabled={supportMutation.isPending}
						>
							Submit
						</Button>
					</Box>
				</form>
			</FormProvider>
			<Button
				fullWidth={true}
				onClick={() => logout(getLogoutOptions())}
				sx={{
					mt: 4,
					background: '#D5D5D5',
					color: COLOR.successText,
				}}
				variant="contained"
			>
				LOGOUT
			</Button>
		</DashboardLayout>
	)
}
