import { Box } from '@mui/material'
import IconWhite from '../assets/images/icon-white.svg'
import { GettingStartedBox } from '../components/getting-started-box.tsx'

export default function LandingPage() {
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}
		>
			<Box
				sx={{
					position: 'fixed',
					zIndex: 1,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					gap: '50px',
				}}
			>
				<Box
					sx={{
						height: 80,
						width: 80,
						'@media (min-width: 1280px)': {
							height: 130,
							width: 130,
						},
					}}
				>
					<img
						src={IconWhite}
						alt="Paybotic logo"
					/>
				</Box>
				<GettingStartedBox />
			</Box>
		</Box>
	)
}
