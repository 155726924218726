import { Box } from '@mui/material'

import { ReactNode } from 'react'
import { ConsumerApp } from './ConsumerApp.tsx'
import { MerchantApp } from './MerchantApp.tsx'
import { useBackgroundImage } from './utils/useBackgroundImage.ts'
import { useIsMerchant } from './utils/useIsMerchant.ts'
import { Loading } from 'src/components/Loading/Loading.tsx'

type Props = {
	children: ReactNode
}

export function AuthenticatedLayout({ children }: Props) {
	const backgroundImage = useBackgroundImage()
	const { isMerchant, isLoading } = useIsMerchant()
	if (isLoading) return <Loading />
	return (
		<Box
			data-testid="AuthenticatedLayout"
			sx={{
				height: '100dvh',
				width: '100vw',
				position: 'relative',
				overflow: 'hidden',
				margin: 0,
				padding: 0,
			}}
		>
			<Box
				sx={{
					overflowY: 'auto',
					position: 'absolute',
					width: '100%',
					height: '100%',
					background: `linear-gradient(#6C9ECD, #092845)`,
					backgroundImage: `url("${backgroundImage}")`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					zIndex: '-1',
					filter: 'blur(2px) contrast(90%)',
				}}
			/>
			{isMerchant ? <MerchantApp>{children}</MerchantApp> : <ConsumerApp>{children}</ConsumerApp>}
		</Box>
	)
}
