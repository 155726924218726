import React from 'react'
import { Container, SxProps, Theme } from '@mui/material'

export function DashboardLayout({ children, sx }: { children: React.ReactNode; sx?: SxProps<Theme> }) {
	return (
		<Container
			sx={{ paddingBottom: '100px', paddingTop: 3, mx: 0, ...sx }}
			style={{ maxWidth: 480 }}
			data-testid="dashboard-layout"
		>
			{children}
		</Container>
	)
}
