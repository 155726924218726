import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'
import AuthenticationGuard from './components/authentication-guard.tsx'
import { PersonalDetailsPage } from './components/dashboard-components/personal-details.tsx'
import { MyRole } from './constants/my-role.ts'
import { MerchantRoutes } from './modules/merchant'
import { PaymentRoutes } from './modules/payment'
import { AccountUnderReview } from './pages/account-under-review.tsx'
import { AlertErrorPage } from './pages/alert-alert-page.tsx'
import { DashboardPage } from './pages/dashboard-page.tsx'
import { ErrorPage } from './pages/error-page.tsx'
import { ErrorUsage } from './pages/error-usage.tsx'
import { FirstLoginTutorialPage } from './pages/first-login-tutorial-page.tsx'
import LandingPage from './pages/landing-page.tsx'
import { LinkAccountPage } from './pages/link-account-page.tsx'
import { LinkAccountSuccessPage } from './pages/link-account-success-page'
import { LinkAccountErrorPage } from './pages/link-account-error-page'
import { ManageAccountsPage } from './pages/manage-accounts.tsx'
import { NotFoundPage } from './pages/not-found-page.tsx'
import { ProfilePage } from './pages/profile-page.tsx'
import RedirectPage from './modules/redirect/pages/redirect-page.tsx'
import { RegistrationPage } from './pages/registration-page.tsx'
import { SettingsPage } from './pages/settings-page.tsx'
import { TestPage } from './pages/test-page.tsx'
import { TransactionHistory } from './pages/transaction-history-page'
import { LogoutPage } from './pages/logout-page.tsx'

import { UnauthenticatedPage } from './pages/unauthenticated.tsx'
import { Loading } from './components/Loading'
import { BusinessUsersRouters } from './modules/business-users'
import { ErrorBoundary } from './components/error/error-boundary.tsx'
import { EmailVerificationPage } from './pages/email-verification.tsx'
import { ReEnableUserPage } from './pages/re-enable-user.tsx'

export default function App() {
	const { isLoading, error } = useAuth0()
	const navigate = useNavigate()
	if (isLoading) {
		return <Loading text="Wait a moment..." />
	}

	if (error) {
		return <div>Oops... {error.message}</div>
	}

	return (
		<ErrorBoundary
			fallback={ErrorPage}
			navigate={navigate}
		>
			<Routes>
				<Route
					path="/"
					element={<LandingPage />}
				/>
				{/* Let this page here for testing components, and other things. We can remove it before going to production  */}
				<Route
					path="/test"
					element={<TestPage />}
				/>
				<Route
					path="/redirect"
					element={<AuthenticationGuard component={RedirectPage} />}
				/>
				<Route path="/dashboard">
					<Route
						index
						element={
							<AuthenticationGuard
								component={DashboardPage}
								requiredRoles={[MyRole.Consumer]}
							/>
						}
						errorElement={<ErrorUsage />}
					/>
					<Route path="/dashboard/profile">
						<Route
							index
							element={
								<AuthenticationGuard
									component={ProfilePage}
									requiredRoles={[MyRole.Consumer]}
								/>
							}
						/>
						<Route
							path="/dashboard/profile/manage-linked-accounts"
							element={
								<AuthenticationGuard
									component={ManageAccountsPage}
									requiredRoles={[MyRole.Consumer]}
								/>
							}
						/>
					</Route>
					<Route
						path="/dashboard/transactions-history"
						element={
							<AuthenticationGuard
								component={TransactionHistory}
								requiredRoles={[MyRole.Consumer]}
							/>
						}
					/>
				</Route>
				<Route path="/dashboard/settings">
					<Route
						index
						element={<AuthenticationGuard component={SettingsPage} />}
					/>
					<Route
						path="/dashboard/settings/personal-details"
						element={<AuthenticationGuard component={PersonalDetailsPage} />}
					/>
				</Route>
				<Route
					path="/registration"
					element={<AuthenticationGuard component={RegistrationPage} />}
				/>
				<Route
					path="/email-verification"
					element={<AuthenticationGuard component={EmailVerificationPage} />}
				/>
				<Route
					path="/re-enable-user"
					element={<AuthenticationGuard component={ReEnableUserPage} />}
				/>
				<Route
					path="/first-login-tutorial"
					element={
						<AuthenticationGuard
							component={FirstLoginTutorialPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="/link-account"
					element={
						<AuthenticationGuard
							component={LinkAccountPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="/link-account-success"
					element={
						<AuthenticationGuard
							component={LinkAccountSuccessPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>
				<Route
					path="/link-account-error"
					element={
						<AuthenticationGuard
							component={LinkAccountErrorPage}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>

				<Route
					path="/account-under-review"
					element={
						<AuthenticationGuard
							component={AccountUnderReview}
							requiredRoles={[MyRole.Consumer]}
						/>
					}
				/>

				<Route
					path="/merchant/*"
					element={<MerchantRoutes />}
				/>
				<Route
					path="/businesses/users/*"
					element={<BusinessUsersRouters />}
				/>
				<Route
					path="/payment/*"
					element={<PaymentRoutes />}
				/>

				<Route
					path="/alert-error-page"
					element={<AuthenticationGuard component={AlertErrorPage} />}
				/>
				<Route
					path="/logout"
					element={<LogoutPage />}
				/>
				<Route
					path="/logout"
					element={<LogoutPage />}
				/>
				<Route
					path="/exception"
					element={<ErrorPage navigate={navigate} />}
				/>
				<Route
					path="/unauthenticated"
					element={<UnauthenticatedPage />}
				/>
				<Route
					path="*"
					element={<NotFoundPage />}
				/>
			</Routes>
		</ErrorBoundary>
	)
}
