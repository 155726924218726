import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { COLOR } from '../../../layout/constants'
import Error from '../assets/error.svg'
import Success from '../assets/success.svg'

import { Animation } from '../../../components/animation'

type Props = {
	status: 'success' | 'error'
}

const getStyle = (status: 'success' | 'error') => {
	if (status === 'error') {
		return {
			icon: Error,
			message: 'Your transaction has failed',
			title: 'FAILED!',
			color: COLOR.error,
		}
	}
	return {
		icon: Success,
		message: 'Your transaction has been approved',
		title: 'SUCCESS!',
		color: COLOR.successText,
	}
}

export const TransactionStatus = ({ status }: Props) => {
	const { icon, message, title, color } = useMemo(() => getStyle(status), [status])
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', mb: 8 }}>
			<div style={{ margin: 'auto', marginBottom: '15px' }}>
				<Animation.Effect02>
					<img
						src={icon}
						alt={status}
					/>
				</Animation.Effect02>
			</div>

			<Typography
				component="h2"
				sx={{
					fontSize: '32px',
					fontWeight: '700',
					lineHeight: '38.73px',
					textAlign: 'center',
					color,
				}}
			>
				{title}
			</Typography>
			<Typography
				component="h2"
				sx={{
					fontSize: '13px',
					fontWeight: '700',
					lineHeight: '15.73px',
					textAlign: 'center',
					color,
				}}
			>
				{message}
			</Typography>
		</Box>
	)
}
