import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toUserDto, User, UserDto } from '../../models/user.models'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { KyInstance } from 'ky'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

async function createUser(httpInstance: KyInstance, user: UserDto) {
	return await httpInstance
		.post('users/self', {
			json: {
				...user,
			},
		})
		.json<User>()
}

export function useCreateUser() {
	const { getAccessTokenSilently } = useAuth0()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { paymentServiceClient } = usePaymentService()
	return useMutation<User, Error, User, User>({
		mutationFn: async (newUser: User) => {
			const userDto = toUserDto(newUser)
			return createUser(paymentServiceClient.httpInstance, userDto)
		},
		// Todo: Should the be onMutate
		onSuccess: async (data: User) => {
			queryClient.setQueryData<User>(['user'], data)
			await getAccessTokenSilently({
				cacheMode: 'off',
			})
			navigate('/redirect')
		},
		onError: (error) => {
			// Todo: Notify the user of the error
			console.error(error)
		},
	})
}
