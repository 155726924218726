import { create } from 'zustand'
import { PurchaseInfo, PurchaseResponse, PurchaseStatus } from '../@types/purchase'
import { BusinessUser } from '../modules/merchant/models/business-user.models'

type AppState = {
	purchase: PurchaseInfo | null
	purchaseResult: PurchaseResponse | null
	purchaseError: string | null
	transactionResult: PurchaseStatus | null
	openMerchantSidebar: boolean
	businessUser: BusinessUser | null
	businessUserLoading: boolean
	setPurchase: (purchase: PurchaseInfo | null) => void
	setPurchaseResult: (purchaseResult: PurchaseResponse | null) => void
	setPurchaseError: (purchaseError: string | null) => void
	setTransactionResult: (transactionResult: PurchaseStatus | null) => void
	setOpenMerchantSidebar: (open: boolean) => void
	setBusinessUser: (businessUser: BusinessUser | null) => void
	setBusinessUserLoading: (lodaing: boolean) => void
}

export const useAppStore = create<AppState>((set) => ({
	purchase: null,
	purchaseResult: null,
	purchaseError: null,
	transactionResult: null,
	openMerchantSidebar: false,
	businessUser: null,
	businessUserLoading: true,
	setPurchase: (purchase) => set({ purchase }),
	setPurchaseResult: (purchaseResult) => set({ purchaseResult }),
	setPurchaseError: (purchaseError) => set({ purchaseError }),
	setTransactionResult: (transactionResult) => set({ transactionResult }),
	setOpenMerchantSidebar: (openMerchantSidebar) => set({ openMerchantSidebar }),
	setBusinessUser: (businessUser) => set({ businessUser }),
	setBusinessUserLoading: (businessUserLoading) => set({ businessUserLoading }),
}))
