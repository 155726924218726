import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { PurchaseResponse } from '../../@types/purchase'

type PurchaseRequest = {
	purchase: string
	amount: number
	notes?: string
	pos_order_id?: string
}

async function initiatePayment(httpInstance: KyInstance, body: PurchaseRequest): Promise<PurchaseResponse> {
	const response = await httpInstance.post('purchases/create-debit', {
		json: body,
	})

	return await response.json<PurchaseResponse>()
}

export function usePayment() {
	const { paymentServiceClient } = usePaymentService()
	return useMutation<PurchaseResponse, Error, PurchaseRequest, string>({
		mutationFn: async (request: PurchaseRequest) => {
			return initiatePayment(paymentServiceClient.httpInstance, request)
		},
		onSuccess: (data: unknown) => {
			console.log(data)
		},
		onError: (error) => {
			console.log(error)
		},
	})
}
