import { Box, Card, Link, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { COLOR } from '../../../layout/constants'
import { useTrasaction } from '../../../react-query/hooks/use-transaction'
import { ProfileItem } from '../components/ProfileItem'
import { Loading } from '../../../components/Loading'

export function MerchantPaymentStatus() {
	const { id } = useParams<{ id: string }>()
	const transaction = useTrasaction(Number(id))
	const profileItemColor = '#000000'
	if (transaction.isLoading) {
		return <Loading />
	}
	if (transaction.isError) {
		return <>Error case</>
	}
	if (!transaction.data) {
		return <>Empty transaction case</>
	}

	return (
		<Card
			sx={{
				margin: '0 auto',
				background: COLOR.white,
				borderRadius: 1,
				width: '90vw',
				maxWidth: '100%',
				marginBottom: '20px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					textAlign: 'center',
					justifyContent: 'center',
					marginBottom: '10px',
					background: '#CDD7E1',
				}}
			>
				<Typography
					component="h1"
					style={{
						fontSize: '14px',
						fontWeight: '600',
						lineHeight: '19.88px',
						padding: '20px',
						color: '#12467B',
					}}
				>
					Payment Successful
				</Typography>
			</Box>
			<Box sx={{ padding: '20px' }}>
				<ProfileItem
					color={profileItemColor}
					label="Purchase Number"
					value="1924401"
				/>
				<ProfileItem
					color={profileItemColor}
					label="Trasaction Date"
					value="03/20/2024"
				/>
				<Box sx={{ marginBottom: '20px' }} />

				<ProfileItem
					color={profileItemColor}
					label="Store Name"
					value="Smoke Green"
				/>
				<ProfileItem
					color={profileItemColor}
					label="Client Name"
					value="William Smith"
				/>
				<ProfileItem
					color={profileItemColor}
					label="Transaction ID"
					value="0423561278"
				/>
				<Box sx={{ marginBottom: '20px' }} />

				<ProfileItem
					color={profileItemColor}
					label="Total amount charged"
					value="$200.00"
				/>
				<Box sx={{ marginBottom: '20px' }} />

				<ProfileItem
					color={profileItemColor}
					label="Total"
					value="$200.00"
				/>
				<Box sx={{ marginBottom: '20px' }} />
				<ProfileItem
					color={profileItemColor}
					label="Notes"
					value="3.5g of Flower & 1g Pre Roll Purple Haze"
				/>

				<Box sx={{ margin: '0 auto', maxWidth: '100%', width: '100%', display: 'flex', justifyContent: 'center' }}>
					<Link>Edit Receipt</Link>
				</Box>
			</Box>
		</Card>
	)
}
