import { UserSchema } from 'src/utils/validations.tsx'

import { Grid } from '@mui/material'
import { PhoneTextField } from '../form-components/phone-text-field.tsx'

export const UserPhoneSchema = UserSchema.pick({
	phone_number: true,
})

export function EditPhone() {
	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<PhoneTextField />
			</Grid>
		</Grid>
	)
}
