import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import { mapStringArrayToPermissionsEnum } from '../constants/my-permissions.ts'
import { usePermissionsStore } from '../store/permissions-store.ts'

type AccessToken = {
	'https://paybotic/personId': string
	'https://paybotic/createdBy': string
	'https://paybotic/payment_center_enabled': boolean | undefined
	email: string
	name: string
	emailVerified: boolean
	paybotic_banking_roles: string[]
	iss: string
	sub: string
	aud: string[] | string // Depending on whether you expect a single string or an array
	iat: number
	exp: number
	scope: string
	azp: string
	permissions: string[]
}

export function usePermissions() {
	const [error, setError] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(true)
	const { getAccessTokenSilently } = useAuth0()
	const [paymentCenterEnabled, setPaymentCenterEnabled] = useState<boolean | undefined>(undefined)
	const [decodedToken, setDecodedToken] = useState<AccessToken | undefined>(undefined)
	const setPermissions = usePermissionsStore((state) => state.setPermissions)
	const permissions = usePermissionsStore((state) => state.permissions)
	const setIsConsumer = usePermissionsStore((state) => state.setIsConsumer)

	useEffect(() => {
		setIsLoading(true)
		getAccessTokenSilently()
			.then((token) => {
				const decodedToken = jwtDecode<AccessToken>(token)
				setDecodedToken(decodedToken)
				const mappedPermissions = mapStringArrayToPermissionsEnum(decodedToken.permissions)
				setPermissions(mappedPermissions)
				setIsConsumer(
					(decodedToken['paybotic_banking_roles'] || []).includes('pc:consumer') ||
						!decodedToken['paybotic_banking_roles']?.length,
				)
				const pcEnabled = decodedToken['https://paybotic/payment_center_enabled']
				if (pcEnabled !== undefined) {
					setPaymentCenterEnabled(pcEnabled)
				}
				setIsLoading(false)
			})
			.catch((e) => {
				setError(e.message || 'An error occurred')
				setIsLoading(false)
			})
	}, [getAccessTokenSilently, setIsConsumer, setPermissions])

	return { isLoading, permissions, paymentCenterEnabled, error, decodedToken }
}
