import { Typography } from '@mui/material'
import { useTransactions } from '../../react-query/hooks/use-transaction.ts'
import { useNavigate } from 'react-router-dom'

import { TransactionsList } from './TransactionsList'

export function RecentTransactions() {
	const { data, isLoading } = useTransactions(3, 0)
	const navigate = useNavigate()

	return (
		<div>
			{data?.items?.length && (
				<Typography
					variant="h5"
					fontWeight="bold"
					sx={{ color: 'white', mb: 2, fontSize: '15px' }}
				>
					Recent Transactions
				</Typography>
			)}

			<TransactionsList
				data={data?.items || []}
				isLoading={isLoading}
				showSeeMoreButton={data?.page_info.has_more}
				seeMoreOnClick={() => navigate('/dashboard/transactions-history')}
			/>
		</div>
	)
}
