import { useAuth0 } from '@auth0/auth0-react'
import { Route, Routes } from 'react-router-dom'
import AuthenticationGuard from '../../components/authentication-guard.tsx'
import { MyRole } from '../../constants/my-role.ts'
import { PaymentConfirmation } from './pages/payment-confirmation'
import { PaymentPage } from './pages/payment-page'
import { Loading } from '../../components/Loading'

export const PaymentRoutes = () => {
	const { isLoading, error } = useAuth0()

	if (isLoading) {
		return <Loading />
	}

	if (error) {
		return <div>Oops... {error.message}</div>
	}

	return (
		<Routes>
			<Route
				path="/"
				element={
					<AuthenticationGuard
						component={PaymentPage}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>
			<Route
				path="/confirmation"
				element={
					<AuthenticationGuard
						component={PaymentConfirmation}
						requiredRoles={[MyRole.Consumer]}
					/>
				}
			/>
		</Routes>
	)
}
