import { StepOne } from './step-one.tsx'
import { StepTwo } from './step-two.tsx'
import { StepThree } from './step-three.tsx'

type Props = {
	currentStep: number
}

export function StepRenderer({ currentStep }: Props) {
	switch (currentStep) {
		case 0:
			return <StepOne />
		case 1:
			return <StepTwo />
		case 2:
			return <StepThree />
		default:
			return null
	}
}
