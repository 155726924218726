import { useState, useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
function debounce(fn: Function, ms = 300) {
	let timeout: ReturnType<typeof setTimeout>
	return function (this: unknown, ...args: unknown[]) {
		clearTimeout(timeout)
		timeout = setTimeout(() => fn.apply(this, args), ms)
	}
}

export function use100vh() {
	const [height, setHeight] = useState(window.innerHeight)

	useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight)
		}

		const handleResizeDebounce = debounce(handleResize, 100)

		window.addEventListener('resize', handleResizeDebounce)
		// Clean up the event listener when the component unmounts
		return () => window.removeEventListener('resize', handleResizeDebounce)
	}, [])

	return height
}
