import { MyTextField } from '../form-components/my-text-field.tsx'
import { Box } from '@mui/material'
import { MyNumberInput } from '../form-components/my-number-input.tsx'
import { useFormContext } from 'react-hook-form'
import { User } from '../../models/user.models.ts'
import { useAuth0 } from '@auth0/auth0-react'
import { PhoneTextField } from '../form-components/phone-text-field.tsx'

export function StepOne() {
	const { user } = useAuth0()
	const {
		control,
		formState: { errors },
	} = useFormContext<User>()
	return (
		<div>
			<MyTextField
				label="First Name"
				error={errors.first_name}
				name="first_name"
				control={control}
				defaultValue=""
				rules={{ required: 'First name required' }}
			/>
			<MyTextField
				label="Last Name"
				error={errors.last_name}
				name="last_name"
				control={control}
				defaultValue=""
				rules={{ required: 'Last name required' }}
			/>
			<MyTextField
				label="Email"
				name="email"
				control={control}
				defaultValue={user?.email}
				error={errors.email}
				disabled={true}
				rules={{ required: 'Email required', pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email address' } }}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<MyNumberInput
					name="dob_month"
					label="Month"
					min={1}
					max={12}
					control={control}
					defaultValue=""
					error={errors.dob_month}
				/>
				<MyNumberInput
					name="dob_day"
					control={control}
					label="Day"
					min={1}
					max={31}
					defaultValue=""
					sx={{ paddingX: 1 }}
					error={errors.dob_day}
				/>
				<MyNumberInput
					name="dob_year"
					label="Year"
					min={1900}
					max={new Date().getFullYear()}
					control={control}
					defaultValue=""
					error={errors.dob_year}
				/>
			</Box>

			<PhoneTextField />
		</div>
	)
}
