import { Box, Button, Paper, Typography, TextField, MenuItem, useMediaQuery, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { COLOR } from '../../../layout/constants.ts'
import { useEffect, useMemo } from 'react'
import { useSupport } from '../../../react-query/hooks/use-support.ts'
import { Support } from '../../../@types/support'
import { Loading } from '../../../components/Loading/Loading'
import { useBusinessUserByAuthId } from '../hooks/use-business-user-by-auth-id.ts'
import { useNavigate } from 'react-router-dom'

const formShape: Record<keyof Support, z.ZodString> = {
	storeName: z.string(),
	issue: z.string().min(1, { message: 'Issue is required' }),
	notes: z.string().min(1, { message: 'Notes is required' }),
}

const formSchema = z.object(formShape)

export function MerchantSupportPage() {
	const navigate = useNavigate()

	const onSuccess = () => {
		navigate('/merchant')
	}
	const supportMutation = useSupport(onSuccess)

	const { data: businessUser, isLoading } = useBusinessUserByAuthId()

	const defaultValues = useMemo(
		() => ({
			storeName: '',
			issue: '',
			notes: '',
		}),
		[],
	)

	const methods = useForm({
		defaultValues,
		resolver: zodResolver(formSchema),
	})
	const {
		handleSubmit,
		formState: { errors },
		setValue,
	} = methods

	const isMobile = useMediaQuery('(max-width:600px)')

	const onSubmit = async (data: Support) => {
		supportMutation.mutate(data)
	}

	useEffect(() => {
		if (businessUser?.BusinessName) {
			setValue('storeName', businessUser?.BusinessName)
		}
	}, [setValue, businessUser?.BusinessName])

	if (isLoading) {
		return <Loading />
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			mt={2}
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<Typography
					sx={{
						fontWeight: 600,
						color: COLOR.textColor,
						textAlign: 'center',
						fontSize: isMobile ? '1rem' : '1.25rem',
						marginBottom: 2,
					}}
				>
					Need support! Fill out this form below
				</Typography>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
								xs={isMobile ? 12 : 6}
							>
								<TextField
									label="Store Name"
									variant="outlined"
									fullWidth
									error={!!errors.storeName}
									disabled={true}
									helperText={errors.storeName ? errors.storeName.message : ''}
									{...methods.register('storeName')}
								/>
							</Grid>
							<Grid
								item
								xs={isMobile ? 12 : 6}
							>
								<TextField
									label="Issue"
									variant="outlined"
									fullWidth
									select
									error={!!errors.issue}
									helperText={errors.issue ? errors.issue.message : ''}
									defaultValue={''}
									{...methods.register('issue')}
								>
									<MenuItem value="Account / Setup">Account / Setup</MenuItem>
									<MenuItem value="Transaction">Transaction</MenuItem>
									<MenuItem value="Technical support">Technical support</MenuItem>
									<MenuItem value="Billing and invoicing">Billing and invoicing</MenuItem>
									<MenuItem value="Product and service">Product and service</MenuItem>
									<MenuItem value="Customer support">Customer support</MenuItem>
									<MenuItem value="Other">Other</MenuItem>
								</TextField>
							</Grid>
							<Grid
								item
								xs={12}
							>
								<TextField
									label="Notes"
									variant="outlined"
									fullWidth
									error={!!errors.notes}
									helperText={errors.notes ? errors.notes.message : ''}
									multiline
									rows={4}
									{...methods.register('notes')}
								/>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</Paper>
			<Box
				display="flex"
				justifyContent="flex-end"
				mt={2}
				sx={{ width: '100%', maxWidth: 600 }}
			>
				<Button
					onClick={handleSubmit(onSubmit)}
					type="submit"
					sx={{ width: 120, height: 40, backgroundColor: COLOR.orange }}
					variant="contained"
					size="large"
				>
					SUBMIT
				</Button>
			</Box>
		</Box>
	)
}
