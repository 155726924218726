import { HTTPError, KyInstance } from 'ky'
import { BusinessUser } from '../models/business-user.models.ts'
import { usePaymentService } from 'src/react-query/services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { Nullable } from 'src/@types/nullable'
import { usePermissionsStore } from 'src/store/permissions-store.ts'
import { usePermissions } from 'src/hooks/use-permissions.ts'

export async function getBusinessUser(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('businesses/user')
		return response.json<BusinessUser>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export const useBusinessUserByAuthId = () => {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	const { isLoading: isLoadingPermissions } = usePermissions()
	const isConsumer = usePermissionsStore((state) => state.isConsumer)
	return useQuery<Nullable<BusinessUser>, Error, Nullable<BusinessUser>>({
		queryKey: ['BusinessUserByAuthId'],
		queryFn: () => getBusinessUser(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated && !isLoadingPermissions && !isConsumer,
		staleTime: 600000, // 10 minutes
	})
}
