import { useBusinessUsers } from '../hooks/use-business-users.ts'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { BusinessUserRow } from './bu-row.tsx'
import { usePermissions } from '../../../hooks/use-permissions.ts'
import { MyPermissions } from '../../../constants/my-permissions.ts'
import { getNameFromRoles } from 'src/constants/my-role.ts'
import { theme } from 'src/theme.ts'

type Props = {
	businessId: number
	businessUserAuth0Id: string
	onEdit: (id: string) => void
	onCancel: () => void
	editingId: string | undefined
}

export const BusinessUsersTable = ({ businessId, businessUserAuth0Id, onEdit, onCancel, editingId }: Props) => {
	const { data: businessUsers } = useBusinessUsers(businessId)
	const { permissions, decodedToken } = usePermissions()
	const allowEdit = permissions?.includes(MyPermissions.UpdateBusinessUsers)
	const allowDelete = permissions?.includes(MyPermissions.DeleteBusinessUsers)

	return (
		<TableContainer component={Paper}>
			<Table data-testid={'business-users-table'}>
				<TableHead>
					<TableRow>
						<TableCell>Username</TableCell>
						<TableCell>Email</TableCell>
						<TableCell>Password</TableCell>
						<TableCell
							sx={{
								[theme.breakpoints!.down!('lg')]: {
									minWidth: '160px',
								},
							}}
						>
							Role
						</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{businessUsers?.map((bu) => (
						<BusinessUserRow
							key={bu.ID}
							row={bu}
							businessUserAuth0Id={businessUserAuth0Id}
							businessUserRole={getNameFromRoles(decodedToken?.paybotic_banking_roles)}
							businessUserCreatedBy={decodedToken?.['https://paybotic/createdBy']}
							allowEdit={allowEdit}
							allowDelete={allowDelete}
							onEdit={onEdit}
							onCancel={onCancel}
							editingId={editingId}
						/>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
