import { Group } from '@mui/icons-material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import {
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	styled,
	Tooltip,
	useMediaQuery,
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import IconWhite from '../../../assets/images/icon-white.svg'
import LogoWhite from '../../../assets/images/logo-white.svg'
import { MyPermissions } from '../../../constants/my-permissions.ts'
import { useAuthorization } from '../../../hooks/use-authorization.ts'
import { usePermissions } from '../../../hooks/use-permissions.ts'
import { COLOR } from '../../../layout/constants.ts'
import { useAppStore } from '../../../store/appStore.ts'
import { usePermissionsStore } from '../../../store/permissions-store.ts'

const Aside = styled('aside')(({ collapsed }: { collapsed: boolean }) => ({
	width: collapsed ? '80px' : '320px',
	transition: 'width 0.3s',
	height: '100%',
	position: 'relative',
	background: 'linear-gradient(180deg, #2F5B84 31.24%, #092845 145.64%)',
	'@media (max-width: 1280px)': {
		display: 'none',
	},
}))

const StyledDrawer = styled(Drawer)({
	'& .MuiDrawer-paper': {
		background: 'linear-gradient(180deg, #2F5B84 31.24%, #092845 145.64%)',
	},
})

const LogoContainer = styled('div')(({ collapsed }: { collapsed: boolean }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: collapsed ? '16px 8px' : '32px 42px',
	transition: 'padding 0.3s',
}))

const StyledMenu = styled(List)(({ collapsed, ismobile: isMobile }: { collapsed: number; ismobile: number }) => ({
	background: '#537BA2',
	padding: 0,
	'& .MuiListItem-root': {
		padding: collapsed ? '0 !important' : '8px 16px',
		'& .MuiButtonBase-root': {
			padding: collapsed ? '1.5rem 1rem' : 'auto',
		},
	},
	'& .MuiListItemIcon-root': {
		color: 'white',
		paddingRight: collapsed ? '0' : '8px',
		justifyContent: 'center',
		width: collapsed ? '100%' : 'initial',
		minWidth: 'auto',
		'& svg': {
			fontSize: collapsed ? '1.75rem' : '1.25rem',
		},
	},
	'& .MuiListItemText-primary': {
		color: 'white',
		display: collapsed ? (isMobile ? 'block' : 'none') : 'block',
	},
	'& .Mui-selected': {
		background: '#CDD7E1 !important',
		'& .MuiTypography-root ': {
			color: '#092845 !important',
		},
		'& .MuiListItemIcon-root': {
			color: '#092845 !important',
		},
	},
	'& .MuiButtonBase-root:hover': {
		background: '#CDD7E1',
		'& span, svg': {
			color: '#092845',
		},
	},
}))

function SidebarMenu({ collapsed = false, isMobile = false }: { collapsed?: boolean; isMobile?: boolean }) {
	const location = useLocation()
	const permissions = usePermissionsStore((state) => state.permissions)
	const setOpenMerchantSidebar = useAppStore((state) => state.setOpenMerchantSidebar)
	const { isLoading: isLoadingPermissions } = usePermissions()
	const { isAuthorized: viewDashboard } = useAuthorization(undefined, [MyPermissions.ViewDashboard])
	// const { isAuthorized: viewTips } = useAuthorization(undefined, [MyPermissions.ViewTips])
	const { isAuthorized: viewReports } = useAuthorization(undefined, [MyPermissions.ViewReports])
	const { isAuthorized: viewSupport } = useAuthorization(undefined, [MyPermissions.ViewSupport])
	const { isAuthorized: viewProfile } = useAuthorization(undefined, [MyPermissions.ViewProfile])
	const { isAuthorized: viewCheckout } = useAuthorization(undefined, [MyPermissions.CreateACHDebit])
	const menuList = [
		{
			label: 'Home',
			route: '/merchant',
			show: viewDashboard,
			icon: <DashboardIcon />,
		},
		{
			label: 'Users',
			route: '/businesses/users',
			show: permissions?.includes(MyPermissions.ReadBusinessUsers),
			icon: <Group />,
		},
		{
			label: 'Tips',
			route: '/merchant/tips',
			show: false,
			icon: <PointOfSaleOutlinedIcon />,
		},
		{
			label: 'Checkout',
			route: '/merchant/checkout',
			show: viewCheckout,
			icon: <ShoppingCartCheckoutOutlinedIcon />,
		},
		{
			label: 'Report',
			route: '/merchant/report',
			show: viewReports,
			icon: <AssessmentOutlinedIcon />,
		},
		{
			label: 'Support',
			route: '/merchant/support',
			show: viewSupport,
			icon: <SupportAgentOutlinedIcon />,
		},
		{
			label: 'Logout',
			route: '/logout',
			show: true,
			icon: <SettingsOutlinedIcon />,
		},
	]
	if (import.meta.env.VITE_FEATURE_MERCHANT_PROFILE === 'true') {
		menuList.push({
			label: 'Profile',
			route: '/merchant/profile',
			show: viewProfile,
			icon: <PersonOutlinedIcon />,
		})
	}

	return (
		<Paper sx={{ width: '100%', minWidth: collapsed ? 'auto' : '250px' }}>
			{isLoadingPermissions ? (
				<ListItem sx={{ justifyContent: 'center' }}>
					<ListItemText>Loading...</ListItemText>
				</ListItem>
			) : (
				menuList
					.filter((item) => item.show)
					.map((item) => (
						<StyledMenu
							ismobile={isMobile ? 1 : 0}
							collapsed={collapsed ? 1 : 0}
							key={item.label}
						>
							<ListItem>
								<Tooltip
									title={item.label}
									placement="right"
								>
									<ListItemButton
										onClick={() => {
											setOpenMerchantSidebar(false)
										}}
										selected={location.pathname === item.route}
										component={Link}
										to={item.route}
									>
										<ListItemIcon>{item.icon}</ListItemIcon>
										<ListItemText>{item.label}</ListItemText>
									</ListItemButton>
								</Tooltip>
							</ListItem>
						</StyledMenu>
					))
			)}
		</Paper>
	)
}

export function Sidebar({
	collapsed,
	setCollapsed,
}: {
	collapsed: boolean
	setCollapsed: (collapsed: boolean) => void
}) {
	const openMerchantSidebar = useAppStore((state) => state.openMerchantSidebar)
	const setOpenMerchantSidebar = useAppStore((state) => state.setOpenMerchantSidebar)

	const isMobile = useMediaQuery('(max-width:1280px)')

	const toggleCollapse = (value?: boolean) => {
		if (isMobile) {
			setOpenMerchantSidebar(!openMerchantSidebar)
		} else {
			if (value !== undefined) {
				setCollapsed(value)
				return
			}
			setCollapsed(!collapsed)
		}
	}

	return (
		<Aside collapsed={collapsed}>
			{isMobile ? (
				<StyledDrawer
					open={openMerchantSidebar}
					onClose={() => setOpenMerchantSidebar(false)}
				>
					<LogoContainer collapsed={collapsed}>
						<img
							src={LogoWhite}
							alt=""
							style={{ maxWidth: '100%' }}
						/>
					</LogoContainer>
					<SidebarMenu
						collapsed={collapsed}
						isMobile={isMobile}
					/>
				</StyledDrawer>
			) : (
				<>
					<LogoContainer collapsed={collapsed}>
						<img
							src={collapsed ? IconWhite : LogoWhite}
							style={{ maxWidth: '100%' }}
							height={collapsed ? 50 : undefined}
							alt=""
						/>
					</LogoContainer>
					<Box sx={{ px: collapsed ? 0 : 3, py: 0 }}>
						<SidebarMenu collapsed={collapsed} />
					</Box>
				</>
			)}
			<IconButton
				onClick={() => toggleCollapse()}
				sx={{
					color: 'white',
					margin: '0.5em',
					bgcolor: COLOR.orange,
					right: '-30px',
					position: 'absolute',
					bottom: '1rem',
					'&:hover': {
						backgroundColor: COLOR.orange,
					},
				}}
			>
				{collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
			</IconButton>
		</Aside>
	)
}
