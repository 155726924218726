import ErrorIcon from '@mui/icons-material/Error'
import { Box, ClickAwayListener, styled, SxProps, Theme, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { useState } from 'react'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip
		{...props}
		arrow
		classes={{ popper: className }}
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}))

type PropsSpendingLimitInfo = {
	sx?: SxProps<Theme> | undefined
}

export function SpendingLimitInfo(props: PropsSpendingLimitInfo) {
	const [open, setOpen] = useState(false)

	const handleTooltipClose = () => {
		setOpen(false)
	}

	const handleTooltipOpen = () => {
		setOpen(true)
		setTimeout(() => {
			setOpen(false)
		}, 3000)
	}

	return (
		<Box
			display={'flex'}
			alignItems={'center'}
			gap={1}
			sx={{
				color: 'white',
				fontSize: '13px',
				...props.sx,
			}}
		>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<BootstrapTooltip
					leaveTouchDelay={3000}
					enterTouchDelay={0}
					placement="bottom"
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					open={open}
					title="$500 daily limit, minus pending purchases"
				>
					<ErrorIcon onClick={handleTooltipOpen} />
				</BootstrapTooltip>
			</ClickAwayListener>
			What is my Spending Limit?
		</Box>
	)
}
