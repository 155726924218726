import { useMutation } from '@tanstack/react-query'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { KyInstance } from 'ky'
import { useAlert } from 'src/lib/useAlert.tsx'
import { getKyError } from 'src/utils/fetch-utils.ts'

const userEmailVerificationSend = async (httpInstance: KyInstance) => {
	await httpInstance.post('users/user/email-verification')
}

export function useUserEmailVerificationSend() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()

	return useMutation<void, Error, void, string>({
		mutationFn: async () => {
			return userEmailVerificationSend(paymentServiceClient.httpInstance)
		},
		onSuccess: async () => {
			triggerSnackbar('Verification e-mail sent', 'success')
		},
		onError: async (error) => {
			console.error(error)
			const errorMessage = await getKyError(error)
			triggerSnackbar(errorMessage || 'Sending verification e-mail failed', 'error')
		},
	})
}
