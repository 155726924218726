import { Box, Button, Card, CardContent, styled, Typography } from '@mui/material'
import { useAppStore } from '../../../../store/appStore.ts'
import { formatCentsToCurrency } from '../../../../utils/money-utils.ts'
import { COLOR } from '../../../../layout/constants.ts'
import { dateFormatter } from '../../../../utils/dates.ts'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { ErrorPage } from '../../../../pages/error-page.tsx'

const StyledCard = styled(Card)(() => ({
	margin: '0 auto',
	background: COLOR.white,
	borderRadius: 1,
	maxWidth: '100%',
	marginBottom: '20px',
	'& .MuiCardContent-root': {
		padding: 0,
	},
}))

const Data = styled('span')(() => ({
	fontSize: '14px',
	fontWeight: 600,
	lineHeight: '21px',
}))

export function MerchantCheckoutSuccess() {
	const navigate = useNavigate()
	const purchase = useAppStore((state) => state.purchaseResult)

	useEffect(() => {
		if (!purchase) {
			navigate('/merchant/checkout')
		}
	}, [])

	if (!purchase) {
		return <ErrorPage />
	}

	return (
		<Box
			mt={2}
			sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}
		>
			<StyledCard>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<div
						style={{
							height: 56,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							background: '#CDD7E1',
						}}
					>
						<Typography
							color="primary"
							variant="h6"
							sx={{ fontSize: 14, fontWeight: 600 }}
						>
							Payment Successful
						</Typography>
					</div>
					<Box sx={{ px: '30px', pt: '10px', pb: '20px', width: '100%' }}>
						<Typography variant="subtitle1">
							{/* todo: purchase.purchase_date should be parsed to Date when received from api */}
							Transaction Date:{' '}
							<Data data-testid="checkout-purchase-date">{dateFormatter.format(new Date(purchase.purchase_date))}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ mb: 2 }}
						>
							Transaction ID: <Data data-testid="checkout-purchase-public-id">{purchase.public_id}</Data>
						</Typography>
						<Typography variant="subtitle1">
							Store Name: <Data data-testid="checkout-purchase-facility-name">{purchase.facility_name}</Data>
						</Typography>
						<Typography variant="subtitle1">
							{/*EMAIL ADDRESS && FIRST AND LAST NAME*/}
							Client Name: <Data data-testid="checkout-purchase-consumer-name">{purchase.consumer_name}</Data>
						</Typography>
						<Typography
							sx={{ mb: 2 }}
							variant="subtitle1"
						>
							POS Order ID: <Data data-testid="checkout-purchase-pos-order-id">{purchase.pos_order_id || '-'}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							Total: <Data data-testid="checkout-purchase-amount">{formatCentsToCurrency(purchase.amount)}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ fontWeight: 'bold' }}
						>
							Fee: <Data data-testid="checkout-purchase-fee-amount">{formatCentsToCurrency(purchase.fee_amount)}</Data>
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ mb: 2 }}
						>
							Notes: <Data data-testid="checkout-purchase-notes">{purchase.notes || '-'}</Data>
						</Typography>
						<Box sx={{ display: 'flex', gap: 1 }}>
							<Button
								component={Link}
								to="/merchant/checkout"
								variant="contained"
								sx={{ bgcolor: 'orange', '&:hover': { bgcolor: 'darkorange' } }}
							>
								New Checkout
							</Button>
							<Button
								component={Link}
								to="/merchant"
								variant="outlined"
							>
								Home
							</Button>
						</Box>
					</Box>
				</CardContent>
			</StyledCard>
		</Box>
	)
}
