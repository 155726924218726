import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'
import { useAvailableCameras } from './useAvailableCameras.ts'
import { useIsMobile } from '../../hooks/use-is-mobile.ts'
import { CameraSelector } from './camera-selector.tsx'
import { CameraDirection, MediaDeviceInfo, MyConstraint } from './qr-types.ts'

export type CameraOption = {
	label: string
	value: string
}

const cameraDirectionValue = {
	BACK: 'environment',
	FRONT: 'user',
}

const cameraDirectionOptions: CameraDirection[] = [
	{ value: cameraDirectionValue.BACK, name: 'Back Camera' },
	{ value: cameraDirectionValue.FRONT, name: 'Front Camera' },
]

type Props = {
	callback: (data: string) => void
}

export const ReadQrCode = ({ callback }: Props) => {
	const [facingMode, setFacingMode] = useState<string>(cameraDirectionValue.BACK)
	const availableDevices = useAvailableCameras()
	const [device, setDevice] = useState<MediaDeviceInfo | undefined>(undefined)
	const { isMobile } = useIsMobile()

	useEffect(() => {
		if (availableDevices.devices.length > 0) {
			const firstBackDevice = availableDevices.devices.find((d) => d.label.toLowerCase().includes('back'))
			setDevice(firstBackDevice || availableDevices.devices[0])
		}
	}, [availableDevices])

	if (availableDevices.loading || device === undefined) {
		return null
	}

	let constraints: MyConstraint = { audio: false }
	let cameraOptions: CameraOption[]
	if (isMobile) {
		constraints = { ...constraints, video: { facingMode } }
		cameraOptions = cameraDirectionOptions.map((o) => ({
			label: o.name,
			value: o.value,
		}))
	} else {
		constraints = { ...constraints, video: { deviceId: device.deviceId } }
		cameraOptions = availableDevices.devices.map((d) => ({
			label: d.label,
			value: d.deviceId,
		}))
	}

	return (
		<Box
			data-testid="ReadQrCode"
			sx={{ width: '100%', height: '100%' }}
		>
			<CameraSelector
				selectedOption={isMobile ? facingMode : device.deviceId}
				options={cameraOptions}
				isMobile={isMobile}
				setFacingMode={setFacingMode}
				setDevice={setDevice}
				availableDevices={availableDevices.devices}
			/>
			<QrReader
				data-testid="ReadQrCode"
				constraints={constraints}
				delay={100}
				style={{
					height: 'calc(100% - 90px)',
					width: '100%',
				}}
				containerStyle={{ marginTop: 5 }}
				onError={console.error}
				onScan={(data: { text: string }) => {
					if (!data) return
					callback(data.text)
				}}
			/>
		</Box>
	)
}
