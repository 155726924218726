import { KyInstance } from 'ky'
import { useState } from 'react'
import { usePaymentService } from '../../../react-query/services/providers/payment-service-provider.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAlert } from '../../../lib/useAlert.tsx'
import { LinkButton } from '../../../components/link-button.tsx'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { CircularProgress } from '@mui/material'
import { BusinessUser } from '../../merchant/models/business-user.models.ts'
import { ConfirmationModal } from '../../../components/modal-components/confirmation-modal.tsx'

const deleteRecord = async (httpInstance: KyInstance, id: number) => {
	await httpInstance.delete(`businesses/user/${id}`)
}

type Props = {
	businessUserId: number
	allowDelete: boolean
	username?: string
}

export const DeleteBusinessUserButton = ({ businessUserId, allowDelete, username }: Props) => {
	const { paymentServiceClient } = usePaymentService()
	const queryClient = useQueryClient()
	const { triggerSnackbar } = useAlert()
	const [open, setOpen] = useState(false)

	const mutation = useMutation<void, Error, number>({
		mutationFn: async () => {
			return deleteRecord(paymentServiceClient.httpInstance, businessUserId)
		},
		onSuccess: async () => {
			await queryClient.setQueryData<BusinessUser[]>(['business-users'], (oldData) => {
				return oldData?.filter((bu) => bu.ID !== businessUserId)
			})
			handleClose()
			triggerSnackbar('Business user deleted successfully', 'success')
		},
		onError: (error) => {
			console.error(error)
			handleClose()
			triggerSnackbar('Failed to delete business user', 'error')
		},
	})

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleDelete = () => {
		mutation.mutate(businessUserId)
	}

	return (
		<>
			{mutation.isPending ? (
				<CircularProgress />
			) : (
				<LinkButton
					handleClick={handleOpen}
					sx={{ minWidth: '20px' }}
					disabled={!allowDelete}
				>
					<DeleteForeverIcon color={allowDelete ? 'error' : 'disabled'} />
				</LinkButton>
			)}
			<ConfirmationModal
				open={open}
				handleClose={handleClose}
				handleAction={handleDelete}
				text={`Are you sure you want to remove ${username}?`}
			/>
		</>
	)
}
