import { FirebaseTimestamp } from '../@types/common'

export function formatDate(date: Date) {
	let hours = date.getHours()
	let minutes = date.getMinutes()
	const ampm = hours >= 12 ? 'PM' : 'AM'
	hours = hours % 12
	hours = hours ? hours : 12
	minutes = minutes < 10 ? 0 + minutes : minutes
	const strTime = hours + ':' + minutes + ' ' + ampm
	return (
		date.getDate() + ' ' + date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear() + ' ' + strTime
	)
}

export function formatFirestoreDate(date: FirebaseTimestamp) {
	const formatDate = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
	return {
		localeString: formatDate.toLocaleTimeString('en-us', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		}),
		date: `${formatDate.getMonth() + 1}/${formatDate.getDate()}/${formatDate.getFullYear()}`,
		time: formatDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }),
	}
}

export function mapStatusToColors(status: string) {
	switch (status) {
		case 'INITIATED':
			return 'secondary'
		case 'ERROR':
			return 'warning'
		case 'COMPLETE':
			return 'success'
		case 'PENDING':
			return 'info'
		default:
			return 'info'
	}
}
