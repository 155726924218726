import './lib/sentry.ts'
import React from 'react'
import '@fontsource/inter'
import '@fontsource/inter/700.css'
import '@fontsource/inter/900.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { theme } from './theme'
import App from './App.tsx'
import Auth0ProviderWithNavigate from './components/auth0-provider-with-navigate.tsx'
import { AppLayout } from './layout/AppLayout'
import { PaymentServiceProvider } from './react-query/services/providers/payment-service-provider.tsx'
import { captureException, withScope } from '@sentry/react'
import { SnackbarProvider } from 'notistack'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import './utils/dates.ts'

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (err, query) => {
			withScope((scope) => {
				scope.setContext('query', { queryHash: query.queryHash })
				scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')])
				captureException(err)
			})
		},
	}),
})

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<BrowserRouter>
			<Auth0ProviderWithNavigate>
				<PaymentServiceProvider>
					<QueryClientProvider client={queryClient}>
						<ThemeProvider theme={theme}>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<SnackbarProvider
									preventDuplicate
									maxSnack={3}
								>
									<CssBaseline />
									<AppLayout>
										<App />
									</AppLayout>
								</SnackbarProvider>
							</LocalizationProvider>
						</ThemeProvider>
						<ReactQueryDevtools />
					</QueryClientProvider>
				</PaymentServiceProvider>
			</Auth0ProviderWithNavigate>
		</BrowserRouter>
	</React.StrictMode>,
)
