import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Link, Paper, Typography } from '@mui/material'
import { Loading } from 'src/components/Loading'
import RedirectPage from 'src/modules/redirect/pages/redirect-page'
import { useUserEmailVerificationSend } from 'src/react-query/hooks/use-user-send-email-verification'

export function EmailVerificationPage() {
	const { user, isLoading } = useAuth0()
	const userEmailVerificationSendMutation = useUserEmailVerificationSend()

	if (isLoading) {
		return <Loading />
	}

	if (user?.email_verified) {
		return <RedirectPage />
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					sx={{ padding: '20px' }}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 'bold' }}
						gutterBottom
					>
						Please verify your email
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						align="center"
					>
						You're almost there! We sent an email to{' '}
						<Box
							component="span"
							sx={{ textDecoration: 'underline', color: 'primary.main' }}
						>
							{user?.email}
						</Box>
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						align="center"
						gutterBottom
					>
						Just click on the link in that email to complete your signup. If you don’t see it, you may need to{' '}
						<Typography
							variant="body2"
							component="span"
							fontWeight="bold"
						>
							check your spam folder
						</Typography>
						.
					</Typography>
					<Typography
						variant="body2"
						gutterBottom
						align="center"
						sx={{ marginTop: 2, marginBottom: 2 }}
					>
						Still can’t find the email
					</Typography>
					<Button
						variant="contained"
						color="warning"
						sx={{ marginTop: '10px' }}
						onClick={() => userEmailVerificationSendMutation.mutate()}
					>
						RESEND EMAIL
					</Button>
					<Typography
						variant="body2"
						sx={{ marginTop: '20px' }}
						align="center"
					>
						Need help? <Link href="mailto:support@payboticfinancial.com">Contact Us</Link>
					</Typography>
				</Box>
			</Paper>
		</Box>
	)
}
