import { Box, Container, styled } from '@mui/material'
import { Navigation } from './component/Navigation.tsx'
import { ReactNode, useState } from 'react'
import { Header as MerchantHeader } from '../../modules/merchant/components/Header.tsx'
import { Sidebar } from '../../modules/merchant/components/Sidebar.tsx'

const MerchantAppBox = styled(Box)({
	display: 'flex',
	height: '100vh',
	'@media (max-width: 1280px)': {
		height: 'calc(100vh - 80px)',
	},
})

const Content = styled(Box)(({ collapsed }: { collapsed: number }) => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	'@media (min-width: 1281px)': {
		width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 320px)',
	},
}))

type Props = {
	children: ReactNode
}
export function MerchantApp({ children }: Props) {
	const [collapsed, setCollapsed] = useState(false)
	return (
		<MerchantAppBox data-testid="MerchantApp">
			<Sidebar
				collapsed={collapsed}
				setCollapsed={setCollapsed}
			/>
			<Content
				data-testid="MerchantApp-Content"
				collapsed={collapsed ? 1 : 0}
			>
				<MerchantHeader />
				<Container
					sx={{ my: { xs: 0, md: 2 }, py: { xs: 2, md: 2 }, flex: 1, overflowY: 'auto' }}
					maxWidth={false}
				>
					{children}
				</Container>
				<Navigation userType="merchant" />
			</Content>
		</MerchantAppBox>
	)
}
