import { Box, Paper, Skeleton, styled, Typography } from '@mui/material'
import React from 'react'
import { COLOR } from '../../../layout/constants.ts'

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: '#fff',
	padding: theme.spacing(2),
	display: 'flex',
	// textAlign: 'center',
	// color: theme.palette.text.secondary,
	'& .MuiTypography-root': {
		fontSize: 16,
		color: COLOR.textColor,
		fontWeight: 600,
	},
	'@media (max-width: 1280px)': {
		height: '104px',
	},
}))

const ColorBox = styled(Box)(() => ({
	width: 48,
	height: 48,
	borderRadius: 4,
	display: 'flex',
	backgroundColor: COLOR.orange,
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 12,
	'& svg': {
		fill: '#fff',
		fontSize: 24,
	},
}))

type DataCardProps = {
	icon: React.ReactNode
	title: string
	label: string
	'data-testid'?: string
	loading?: boolean
}

export function DataCard({ icon, title, label, 'data-testid': testId, loading }: DataCardProps) {
	if (loading) {
		return (
			<Skeleton
				variant="rectangular"
				height={104}
			/>
		)
	}

	return (
		<Item data-testid={testId ?? 'DataCard'}>
			<ColorBox>{icon}</ColorBox>
			<Box
				display={'flex'}
				flexDirection={'column'}
			>
				<Typography>{title}</Typography>
				<Typography>{label}</Typography>
			</Box>
		</Item>
	)
}
