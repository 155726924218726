import { Box, Card, Typography } from '@mui/material'
import { COLOR } from '../../../layout/constants'
import { ProfileItem } from '../components/ProfileItem'
import { Loading } from '../../../components/Loading'
import { useBusinessUserByAuthId } from '../hooks/use-business-user-by-auth-id'

export function MerchantProfile() {
	const { data: businessUser, isLoading } = useBusinessUserByAuthId()

	if (isLoading || !businessUser) {
		return <Loading />
	}

	return (
		<>
			<Card
				sx={{
					margin: '0 auto',
					background: COLOR.white,
					borderRadius: 1,
					marginBottom: '20px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						textAlign: 'center',
						justifyContent: 'center',
						marginBottom: '0px',
					}}
				>
					<Typography
						component="h1"
						style={{
							fontSize: '16px',
							fontWeight: '600',
							lineHeight: '19.88px',
							padding: '0px',
							color: '#12467B',
							margin: '25px 0px 15px',
						}}
					>
						Personal Information Details
					</Typography>
				</Box>
				<Box sx={{ padding: '0px 25px 25px' }}>
					{/* <ProfileItem
						label="First Name"
						value="Jhon"
					/>
					<ProfileItem
						label="Last Name"
						value="Smith"
					/>
					<Box sx={{ marginBottom: '20px' }} /> */}

					<ProfileItem
						label="Store Name"
						value={businessUser.BusinessName}
					/>
					<ProfileItem
						label="Email"
						value={businessUser.Email}
					/>
					{/* <Box sx={{ marginBottom: '20px' }} />

					<ProfileItem
						label="Address"
						value="1880 Court Ave. 15th St. West Palm Beach. FL 00000"
					/> */}
				</Box>
			</Card>
		</>
	)
}
