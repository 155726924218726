import { Typography } from '@mui/material'
import { useInfiniteTransactions } from '../react-query/hooks/use-transaction'
import { TransactionsList } from 'src/components/dashboard-components/TransactionsList'
import { DashboardLayout } from 'src/components/dashboard-components/dashboard-layout'
import MyCard from 'src/components/dashboard-components/my-card'

export function TransactionHistory() {
	const { data, isLoading, error, fetchNextPage, hasNextPage } = useInfiniteTransactions()

	const transactions = data?.pages.flatMap((page) => page.items) || []

	if (error) {
		return (
			<DashboardLayout>
				<MyCard>
					<Typography
						variant="h5"
						fontWeight="bold"
						sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}
					>
						Failed to load transactions
					</Typography>
				</MyCard>
			</DashboardLayout>
		)
	}
	if (!isLoading && transactions.length === 0) {
		return (
			<DashboardLayout>
				<MyCard>
					<Typography
						variant="h5"
						fontWeight="bold"
						sx={{ color: 'white', fontSize: '15px', textAlign: 'center' }}
					>
						This user has no transactions
					</Typography>
				</MyCard>
			</DashboardLayout>
		)
	}

	return (
		<DashboardLayout>
			<div data-testid="TransactionHistory">
				<Typography
					variant="h5"
					fontWeight="bold"
					sx={{ color: 'white', mb: 2, fontSize: '15px' }}
				>
					All Transactions
				</Typography>

				<TransactionsList
					data={transactions}
					isLoading={isLoading}
					showSeeMoreButton={hasNextPage}
					seeMoreOnClick={() => fetchNextPage()}
				/>
			</div>
		</DashboardLayout>
	)
}
