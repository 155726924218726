import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'

type Props = {
	children: ReactNode
}

// https://community.auth0.com/t/how-to-retrieve-refresh-token-using-auth0-auth0-react/69700/2
export default function Auth0ProviderWithNavigate({ children }: Props) {
	const navigate = useNavigate()
	const domain = import.meta.env.VITE_AUTH0_DOMAIN
	const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID
	const redirectUri = window.location.origin + import.meta.env.VITE_AUTH0_REDIRECT_URI
	const audience = import.meta.env.VITE_AUTH0_AUDIENCE
	function onRedirectCallback(appState: AppState | undefined) {
		navigate(appState?.returnTo || window.location.pathname)
	}
	if (!(domain && clientId && redirectUri && audience)) {
		throw new Error('Missing required environment variables')
	}
	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			onRedirectCallback={onRedirectCallback}
			useRefreshTokens={true}
			authorizationParams={{
				redirect_uri: redirectUri,
				audience: audience,
				scope: 'openid profile email offline_access permissions',
			}}
		>
			{children}
		</Auth0Provider>
	)
}
