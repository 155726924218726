import RefreshIcon from '@mui/icons-material/Refresh'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { doc, onSnapshot } from 'firebase/firestore'
import { QRCodeSVG } from 'qrcode.react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Purchase } from 'src/models/purchase.models.ts'
import { usePurchase } from 'src/react-query/hooks/use-purchase.ts'
import { useTimeout } from 'usehooks-ts'
import { PurchaseStatus } from '../../../@types/purchase'
import Logo from '../../../assets/images/p-logo.png'
import { DashboardLayout } from '../../../components/dashboard-components/dashboard-layout.tsx'
import MyCard from '../../../components/dashboard-components/my-card.tsx'
import { db } from '../../../lib/firebase'
import { usePurchasePower } from '../../../react-query/hooks/use-purchase-power.ts'
import { useAppStore } from '../../../store/appStore.ts'
import { Loading } from 'src/components/Loading/Loading.tsx'
import { PurchasePower } from '../../../components/purchase-power.tsx'

export function PaymentPage() {
	const [purchase, setPurchase] = useState<Purchase | undefined>(undefined)
	const { data: purchasePower, isLoading: isLoadingPurchasePower } = usePurchasePower()
	const setTransactionResult = useAppStore((state) => state.setTransactionResult)
	const navigate = useNavigate()
	const isSmallHeight = useMediaQuery('(max-height:750px)')

	const [qrCodeExpired, setQrCodeExpired] = useState(false)
	const [timeRemaining, setTimeRemaining] = useState(0)

	const updateQrCode = useCallback((data: Purchase) => {
		setPurchase(data)
		const expirationTime = new Date(data.Expiration).getTime()
		const currentTime = new Date().getTime()
		const timeRemainingCounter = expirationTime - currentTime - 10000
		setTimeRemaining(timeRemainingCounter)
		setQrCodeExpired(false)
	}, [])

	const { addPurchase, isPending, isSuccess, isError } = usePurchase({ onSuccess: updateQrCode })
	const isLoading = isLoadingPurchasePower || isPending || (!isSuccess && !isError)

	// First load only
	useEffect(() => {
		addPurchase()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Timer for QR code expiration
	useTimeout(() => {
		if (purchase) {
			setQrCodeExpired(true)
			setTimeRemaining(0)
		}
	}, timeRemaining)

	useEffect(() => {
		if (!purchase) return
		const unsubscribe = onSnapshot(
			doc(db, 'purchases', purchase.ID || ''),
			(doc) => {
				if (doc.exists()) {
					setTransactionResult(doc.data() as PurchaseStatus)
					navigate('/payment/confirmation')
				}
			},
			(error) => {
				console.error('Error listening to the document: ', error)
			},
		)
		// Cleanup function to detach the listener
		return () => {
			unsubscribe()
		}
	}, [navigate, setTransactionResult, purchase])

	if (isLoading) {
		return <Loading />
	}

	return (
		<DashboardLayout>
			<Typography
				variant="h4"
				fontWeight="bold"
				sx={{ color: 'white', mb: 2, fontSize: '17px', textAlign: 'center' }}
			>
				Home
			</Typography>
			<div data-testid={'qr-code-container'}>
				<PurchasePower
					purchasePower={purchasePower}
					message="Show merchant QR code"
				/>
				<MyCard
					sx={{ mt: 2, p: 4, position: 'relative' }}
					variant="secondary"
				>
					<Box
						display={'flex'}
						justifyContent="center"
						alignItems="center"
					>
						{purchase?.ID && (
							<QRCodeSVG
								value={`${location.protocol}//${location.host}/merchant/payment/${purchase.ID}`}
								size={isSmallHeight ? 180 : 235}
								level="H"
								includeMargin={!isSmallHeight}
								imageSettings={{
									src: Logo,
									height: isSmallHeight ? 30 : 50,
									width: isSmallHeight ? 30 : 50,
									excavate: true,
								}}
							/>
						)}
						{qrCodeExpired && (
							<MyCard
								sx={{
									mt: 2,
									p: 4,
									position: 'absolute',
									width: '235px',
									height: '235px',
									backgroundColor: 'rgba(255, 255, 255, 0.3)',
									backdropFilter: 'blur(12px)',
									boxShadow: 'none',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
								variant="secondary"
							>
								<Box
									onClick={() => addPurchase()}
									sx={{
										width: '110px',
										height: '110px',
										backgroundColor: 'rgba(255, 255, 255, 0.9)',
										borderRadius: '50%',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										cursor: 'pointer',
									}}
								>
									<RefreshIcon sx={{ color: '#003366', fontSize: '4rem' }} />
									<Typography
										sx={{
											color: '#003366',
											fontWeight: 'bold',
											fontSize: '1rem',
											mt: -1,
										}}
									>
										Refresh
									</Typography>
								</Box>
							</MyCard>
						)}
					</Box>
				</MyCard>
			</div>
		</DashboardLayout>
	)
}
