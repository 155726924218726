import { Typography } from '@mui/material'
import { COLOR } from '../../../layout/constants'

type Props = {
	children: React.ReactNode
}

export const Title = ({ children }: Props) => {
	return (
		<Typography
			variant="h4"
			fontWeight="bold"
			sx={{
				fontSize: '17px',
				lineHeight: '20.57px',
				textAlign: 'center',
				color: COLOR.darkPrimary,
				marginBottom: '15px',
			}}
		>
			{children}
		</Typography>
	)
}
