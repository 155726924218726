import { z } from 'zod'
import { HTTPError } from 'ky'

export enum ErrorCode {
	ErrExternalAccountNotFound = 'ERR_EXTERNAL_ACCOUNT_NOT_FOUND',
	ErrUserExists = 'ERR_USER_EXISTS',
}

export const ErrorResponse = z.object({
	message: z.string(),
	status: z.number(),
	code: z.nativeEnum(ErrorCode),
})

export async function parseHttpError(error: HTTPError) {
	const contentType = error.response.headers.get('content-type')
	if (error.response.status >= 500 || contentType?.replace(';', '') !== 'application/json') {
		return { parsed: false, data: null }
	}
	const body = await error.response.json()
	const result = ErrorResponse.safeParse(body)
	if (!result.success) {
		return { parsed: false, data: null }
	}
	return { parsed: result.success, data: result.data }
}
