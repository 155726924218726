import {
	Box,
	Button,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	Paper,
	TextField,
	Typography,
	Grid,
	styled,
} from '@mui/material'
import { MyTextField } from '../../../components/form-components/my-text-field.tsx'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { BusinessUser, BusinessUserType } from '../schemas/business-users.schema.ts'
import { RoleSelect } from '../components/role-select.tsx'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useCreateBusinessUser } from '../hooks/use-create-business-user.ts'
import { COLOR } from '../../../layout/constants.ts'

const Container = styled(Paper)({
	padding: '20px',
	backgroundColor: 'white',
	margin: '20px',
	maxWidth: '1080px',
})

export const CreateBusinessUsersPage = () => {
	const { createBusinessUserMutation } = useCreateBusinessUser()
	const navigate = useNavigate()
	const [showPassword, setShowPassword] = useState(false)
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<BusinessUserType>({
		mode: 'onBlur',
		resolver: zodResolver(BusinessUser),
		defaultValues: {
			password: '',
		},
	})

	const handleClickShowPassword = () => setShowPassword(!showPassword)
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()

	const onSubmit = async (data: BusinessUserType) => {
		createBusinessUserMutation.mutate(data)
	}

	const cancelUserCreate = useCallback(() => {
		navigate('/businesses/users')
	}, [navigate])

	return (
		<Container elevation={3}>
			<Typography
				variant="h3"
				color={COLOR.darkBlue}
				fontSize="20px"
				fontWeight="bold"
			>
				Add New User
			</Typography>
			<Divider
				variant="fullWidth"
				style={{ margin: '20px 0' }}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
						md={12}
						sm={12}
						lg={3}
					>
						<MyTextField
							label="Email"
							name="email"
							defaultValue=""
							control={control}
							error={errors.email}
							rules={{
								required: 'Email required',
								pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email address' },
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						sm={12}
						lg={3}
					>
						<MyTextField
							label="Username"
							name="username"
							defaultValue=""
							control={control}
							error={errors.username}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						sm={12}
						lg={3}
					>
						<Controller
							name="password"
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<TextField
									value={value}
									onChange={onChange}
									fullWidth
									label="Password"
									name="password"
									error={!!error}
									helperText={error?.message}
									margin="dense"
									size="small"
									type={showPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{showPassword ? <Visibility /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={12}
						sm={12}
						lg={3}
					>
						<RoleSelect
							name={'role'}
							control={control}
							error={errors.role}
						/>
					</Grid>
				</Grid>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						marginTop: '20px',
						flexWrap: 'wrap',
					}}
				>
					<Button
						onClick={cancelUserCreate}
						variant="contained"
						color="primary"
						sx={{
							width: {
								xs: '100%',
								sm: 'auto',
							},
						}}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="contained"
						color="secondary"
						sx={{
							width: {
								xs: '100%',
								sm: 'auto',
							},
							marginTop: {
								xs: 1.5,
								sm: 'auto',
							},
						}}
					>
						{createBusinessUserMutation.isPending ? (
							<CircularProgress
								color="inherit"
								size={28}
							/>
						) : (
							'Confirm'
						)}
					</Button>
				</Box>
			</form>
		</Container>
	)
}
