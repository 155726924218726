import { ContentCard } from './content-card.tsx'
import { Box, Typography } from '@mui/material'
import { LoginBtn } from './login-btn.tsx'
import { SignUpBtn } from './sign-up-btn.tsx'

export function GettingStartedBox() {
	return (
		<Box
			mt={2}
			sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}
		>
			<ContentCard
				sx={{
					margin: '0 15px',
				}}
			>
				<Typography
					variant="body2"
					align="center"
				>
					Convenient, Secure Solutions for Every Transaction, Today and Tomorrow
				</Typography>
				<SignUpBtn />
				<Typography
					variant="body2"
					align="center"
				>
					Already have an account?{' '}
					<LoginBtn
						variant="text"
						sx={{
							textDecoration: 'underline',
							padding: '0 0 4px 0',
						}}
					/>
				</Typography>
				<Typography
					variant="body2"
					align="center"
					sx={{
						color: '#898989',
						paddingTop: '20px',
						fontSize: '13px',
					}}
				>
					Paybotic Financial is a financial technology company, not a bank. Banking services are provided by Regent
					Bank, Member FDIC.
				</Typography>
			</ContentCard>
		</Box>
	)
}
