import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Box, Paper, styled, Typography } from '@mui/material'
import { COLOR } from '../../../layout/constants.ts'
import { LinkButton } from '../../../components/link-button.tsx'
import { useNavigate } from 'react-router-dom'
import { useBusinessUserByAuthId } from '../../merchant/hooks/use-business-user-by-auth-id.ts'
import { BusinessUsersTable } from '../components/bu-table.tsx'
import { MyPermissions } from '../../../constants/my-permissions.ts'
import { usePermissionsStore } from '../../../store/permissions-store.ts'
import { Loading } from '../../../components/Loading'
import { useState } from 'react'

const Container = styled(Paper)({
	padding: '1rem',
	width: '100%',
	'@media (min-width: 1280px)': {
		padding: '2rem',
	},
})

const HeaderWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	fontWeight: 'bold',
	fontSize: '16px',
	marginBottom: '30px',
})

const SubHeader = styled(Typography)({
	color: COLOR.primary,
	fontWeight: 'bold',
	fontSize: '20px',
})

const AddUserButton = styled(LinkButton)({
	color: COLOR.green,
})

export const ListBusinessUsersPage = () => {
	const navigate = useNavigate()
	const { data: businessUser, isLoading } = useBusinessUserByAuthId()
	const [editingId, setEditingId] = useState<string>()
	const permissions = usePermissionsStore((state) => state.permissions)
	const createPersonEnabled = permissions.includes(MyPermissions.CreateBusinessUsers)

	if (isLoading || !businessUser) {
		return <Loading />
	}

	return (
		<Container data-testid="ListBusinessUsersPage">
			<HeaderWrapper>
				<SubHeader>Users</SubHeader>
				{createPersonEnabled && (
					<AddUserButton
						disabled={!!editingId}
						handleClick={() => navigate('/businesses/users/create')}
						size="large"
					>
						<PersonAddIcon
							sx={{ color: editingId ? COLOR.darkPrimary : COLOR.green, marginRight: '8px' }}
							fontSize="small"
						/>
						Add User
					</AddUserButton>
				)}
			</HeaderWrapper>
			<BusinessUsersTable
				businessId={businessUser.BusinessID}
				businessUserAuth0Id={businessUser.Auth0ID}
				onEdit={setEditingId}
				onCancel={() => setEditingId(undefined)}
				editingId={editingId}
			/>
		</Container>
	)
}
