import { Box, styled } from '@mui/material'

import { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from './component/Header'
import { Navigation } from './component/Navigation.tsx'
import { useShowHeader } from './utils/useShowHeader.ts'
import { useShowNavigation } from './utils/useShowNavigation.ts'

type Props = {
	children: ReactNode
}
const AuthenticatedLayoutContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	zIndex: 1,
})
export function ConsumerApp({ children }: Props) {
	const location = useLocation()
	const isInDashboard =
		(location.pathname.includes('/dashboard') && !location.pathname.includes('/merchant')) ||
		location.pathname === '/payment'
	const showNavigation = useShowNavigation()
	const showHeader = useShowHeader()

	return (
		<>
			<AuthenticatedLayoutContainer data-testid="AuthenticatedLayout-Container">
				{showHeader ? <Header /> : null}
				<Box
					data-testid="AuthenticatedLayout-Main"
					component={'main'}
					display="flex"
					flexDirection="column"
					alignItems="center"
					height={'100%'}
					sx={{
						padding: isInDashboard ? '0px 17px' : 0,
						marginBottom: isInDashboard ? '70px' : 0,
						marginTop: '120px',
						flex: 1,
						overflowY: 'auto',
						zIndex: 1,
						'@media (max-height: 750px)': {
							marginTop: '60px',
						},
					}}
				>
					{children}
				</Box>
			</AuthenticatedLayoutContainer>
			{showNavigation ? <Navigation userType="consumer" /> : null}
		</>
	)
}
