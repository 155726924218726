import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useUser } from '../../react-query/hooks/use-user.ts'

import { DashboardLayout } from './dashboard-layout.tsx'
import { Detail, EditableEdividualDetails } from './detail-row.tsx'
import { COLOR } from '../../layout/constants.ts'
import { Loading } from '../Loading'

import {
	useUpdateUserAddress,
	useUpdateUserName,
	useUpdateUserPhone,
} from 'src/react-query/hooks/use-user-update-self.ts'
import { EditName, UserNameSchema } from '../personal-details/edit-name.tsx'
import { EditPhone, UserPhoneSchema } from '../personal-details/edit-phone.tsx'
import { EditAddress, UserAddressSchema } from '../personal-details/edit-address.tsx'
import { User } from 'src/models/user.models.ts'

export function PersonalDetailsPage() {
	const { isLoading, data: user } = useUser()
	const [currentModal, setCurrentModal] = useState<EditableEdividualDetails>()
	const navigate = useNavigate()

	const updateUserNameMutation = useUpdateUserName()
	const updateUserPhoneMutation = useUpdateUserPhone()
	const updateUserAddressMutation = useUpdateUserAddress()

	const formSchemas: Record<
		EditableEdividualDetails,
		typeof UserNameSchema | typeof UserPhoneSchema | typeof UserAddressSchema
	> = {
		Name: UserNameSchema,
		Phone: UserPhoneSchema,
		Address: UserAddressSchema,
	}

	const methods = useForm<User>({
		mode: 'all',
		resolver: zodResolver(formSchemas[currentModal || 'Name']),
	})

	const setMethodUserValues = useCallback(() => {
		if (!user) {
			return
		}
		for (const key of Object.keys(user)) {
			methods.setValue(key as keyof User, user[key as keyof User])
		}
	}, [user, methods])

	useEffect(() => {
		setMethodUserValues()
	}, [methods, user, setMethodUserValues])

	if (isLoading || !user || updateUserNameMutation.isPending) {
		return <Loading />
	}

	const onSubmitUserName = async () => {
		const { first_name, last_name } = methods.getValues()
		updateUserNameMutation.mutate({
			first_name,
			last_name,
		})
	}

	const onSubmitUserPhone = async () => {
		const { phone_number } = methods.getValues()
		updateUserPhoneMutation.mutate({
			phone_number,
		})
	}

	const onSubmitUserAddress = async () => {
		const { address } = methods.getValues()
		updateUserAddressMutation.mutate({
			address,
		})
	}

	const onSubmitModal = async (onSubmit: () => void) => {
		await methods.handleSubmit(onSubmit)()
		return methods
	}

	const onEdit = (modelType: EditableEdividualDetails) => {
		methods.clearErrors()
		setCurrentModal(modelType)
	}

	return (
		<DashboardLayout>
			<FormProvider {...methods}>
				<Typography
					variant="h4"
					fontWeight="bold"
					sx={{ color: '#244D74', mb: 2, fontSize: '17px', textAlign: 'center' }}
				>
					Personal Details
				</Typography>
				<Box
					display={'flex'}
					flexDirection={'column'}
					gap={4}
					px={4}
					mt={4}
				>
					<Detail
						label="Name"
						value={`${user?.first_name} ${user?.last_name}`}
						onEdit={onEdit}
						editModalComponent={<EditName />}
						errors={methods.formState.errors}
						onConfirm={() => onSubmitModal(onSubmitUserName)}
						onCancel={() => setMethodUserValues()}
					/>
					<Detail
						label="Address"
						value={`${user?.address.street_one} ${user?.address.city} ${user?.address.state}, ${user?.address.postal_code}`}
						onEdit={onEdit}
						editModalComponent={<EditAddress />}
						errors={methods.formState.errors}
						onConfirm={() => onSubmitModal(onSubmitUserAddress)}
						onCancel={() => setMethodUserValues()}
					/>
					<Detail
						label="Phone"
						value={user?.phone_number}
						onEdit={onEdit}
						editModalComponent={<EditPhone />}
						errors={methods.formState.errors}
						onConfirm={() => onSubmitModal(onSubmitUserPhone)}
						onCancel={() => setMethodUserValues()}
					/>
					<Detail
						label="Email"
						value={user?.email}
					/>
				</Box>
				<Button
					fullWidth={true}
					sx={{
						mt: 8,
						background: '#D5D5D5',
						color: COLOR.darkPrimary,
					}}
					variant={'contained'}
					onClick={() => navigate('/dashboard/settings')}
				>
					Go back
				</Button>
			</FormProvider>
		</DashboardLayout>
	)
}
