import { AppBar, Box, Toolbar } from '@mui/material'
import LogoBlue from '../../../assets/images/logo-blue.svg'

export function Header() {
	const imageStyles = {
		height: 'auto',
		width: '100%',
	}
	return (
		<Box>
			<AppBar
				component="nav"
				sx={{
					height: '120px',
					display: 'flex',
					flexGrow: 1,
					position: 'fixed',
					width: '100%',
					backgroundColor: 'white',
					boxShadow: 'none',
					top: 0,
					'@media (max-height: 750px)': {
						height: '70px',
					},
				}}
			>
				<Toolbar sx={{ height: '100%', justifyContent: 'center' }}>
					<Box
						sx={{
							display: 'flex',
							alignContent: 'center',
							maxWidth: '300px',
							'@media (max-height: 750px)': {
								maxWidth: '200px',
							},
						}}
					>
						<img
							src={LogoBlue}
							alt="Paybotic logo"
							style={imageStyles}
						/>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	)
}
