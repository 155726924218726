import { useFormContext } from 'react-hook-form'
import { MyTextField } from '../form-components/my-text-field'
import { PartialEditUserName } from '../../models/user.models.ts'
import { UserSchema } from 'src/utils/validations.tsx'
import { Grid } from '@mui/material'

export const UserNameSchema = UserSchema.pick({
	first_name: true,
	last_name: true,
})

export function EditName() {
	const {
		control,
		formState: { errors },
	} = useFormContext<PartialEditUserName>()

	return (
		<>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={6}
				>
					<MyTextField
						label="First Name"
						error={errors.first_name}
						name="first_name"
						control={control}
						rules={{ required: 'First name required' }}
					/>
				</Grid>
				<Grid
					item
					xs={6}
				>
					<MyTextField
						label="Last Name"
						error={errors.last_name}
						name="last_name"
						control={control}
						rules={{ required: 'Last name required' }}
					/>
				</Grid>
			</Grid>
		</>
	)
}
