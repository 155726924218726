import { useAuth0 } from '@auth0/auth0-react'
import { ReactNode } from 'react'
import { AuthenticatedLayout } from './AuthenticatedLayout.tsx'
import { UnauthenticatedLayout } from './UnauthenticatedLayout.tsx'

type Props = {
	children: ReactNode
}
export function AppLayout({ children }: Props) {
	// Todo: Add loading check to prevent background flicker
	const { isAuthenticated } = useAuth0()
	if (!isAuthenticated) return <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
	return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}
