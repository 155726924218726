import { HTTPError, KyInstance } from 'ky'
import { BusinessUserType } from '../schemas/business-users.schema.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { usePaymentService } from '../../../react-query/services/providers/payment-service-provider.tsx'
import { ErrorCode, parseHttpError } from '../../../react-query/errors.ts'
import { useAlert } from '../../../lib/useAlert.tsx'

const createBusinessUser = async (httpInstance: KyInstance, businessUser: BusinessUserType) => {
	return await httpInstance
		.post('businesses/user', {
			json: {
				...businessUser,
			},
		})
		.json<BusinessUserType>()
}

export const useCreateBusinessUser = () => {
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { paymentServiceClient } = usePaymentService()
	const createBusinessUserMutation = useMutation<BusinessUserType, HTTPError, BusinessUserType, BusinessUserType>({
		mutationFn: async (newBusinessUser: BusinessUserType) => {
			return createBusinessUser(paymentServiceClient.httpInstance, newBusinessUser)
		},
		onSuccess: async (data: BusinessUserType) => {
			// Todo: Will need to update the query to add to list of business users
			queryClient.setQueryData<BusinessUserType>(['/businesses/users'], data)
			triggerSnackbar('User created successfully', 'success')
			navigate('/businesses/users')
		},
		onError: async (error) => {
			const { parsed, data } = await parseHttpError(error)
			if (parsed && data?.code === ErrorCode.ErrUserExists) {
				triggerSnackbar('User already exists', 'error')
			} else {
				triggerSnackbar('Something went wrong creating user', 'error')
			}
		},
	})
	return { createBusinessUserMutation }
}
