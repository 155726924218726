import { MyPermissions } from '../constants/my-permissions.ts'
import { create } from 'zustand'

type PermissionsState = {
	permissions: MyPermissions[]
	isConsumer: boolean | null
	setPermissions: (permissions: MyPermissions[]) => void
	setIsConsumer: (isConsumer: boolean) => void
}

export const usePermissionsStore = create<PermissionsState>((set) => ({
	permissions: [],
	isConsumer: null,
	setPermissions: (permissions) => set(() => ({ permissions })),
	setIsConsumer: (isConsumer) => set(() => ({ isConsumer })),
}))
