import { ContentCard } from '../components/content-card.tsx'
import { Box, Container, Link } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { User } from '../models/user.models.ts'
import { useAuth0 } from '@auth0/auth0-react'
import { BtnLg } from '../components/btn-lg.tsx'
import { RegistrationHeader } from '../components/registration-header.tsx'
import { useEffect, useState } from 'react'
import { StepRenderer } from '../components/registration-components/step-renderer.tsx'
import { useCreateUser } from '../react-query/hooks/use-create-user.ts'
import { StepOneSchema, StepThreeSchema, StepTwoSchema } from '../utils/validations.tsx'
import { zodResolver } from '@hookform/resolvers/zod'
import { MyRole } from '../constants/my-role.ts'
import { hasBusinessUserRole } from '../utils/role-check.ts'
import { Loading } from '../components/Loading'
import { useNavigate } from 'react-router-dom'
import { useDeactivatedUser } from 'src/react-query/hooks/use-get-deactivated-user.ts'
import { useUser } from '../react-query/hooks/use-user.ts'
import RedirectPage from 'src/modules/redirect/pages/redirect-page.tsx'

export type Step = {
	step: number
	title: string
	subtitle: string
}

const steps: Step[] = [
	{
		step: 0,
		title: 'Personal Information',
		subtitle: 'In order to open an account your current address must be within United States',
	},
	{
		step: 1,
		title: 'Home Address',
		subtitle: 'In order to open an account your current address must be within United States',
	},
	{
		step: 2,
		title: 'Terms and Conditions ',
		subtitle: '',
	},
]

export function RegistrationPage() {
	const [currentStep, setCurrentStep] = useState(0)
	const { user, isLoading } = useAuth0()
	const {
		data: deactivatedUser,
		isLoading: isLoadingDeactivatedUser,
		status: statusGetDeactivatedUser,
	} = useDeactivatedUser()
	const { data: dbUser, isLoading: dbUserLoading } = useUser()
	const navigate = useNavigate()
	const createUserMutation = useCreateUser()

	const stepSchemas = [StepOneSchema, StepTwoSchema, StepThreeSchema]
	const currentStepSchema = stepSchemas[currentStep]
	const methods = useForm<User>({
		mode: 'all',
		resolver: zodResolver(currentStepSchema),
	})

	useEffect(() => {
		if (isLoading || currentStep !== 0 || isLoadingDeactivatedUser || statusGetDeactivatedUser !== 'success') {
			return
		}

		if (deactivatedUser) {
			navigate('/re-enable-user')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoadingDeactivatedUser])

	if (isLoading || isLoadingDeactivatedUser || deactivatedUser || dbUserLoading) {
		return <Loading />
	}

	if (dbUser) {
		return <RedirectPage />
	}

	// Todo: Reconcile with other logic
	const userRoles: MyRole[] = user?.['paybotic_banking_roles'] || []
	if (hasBusinessUserRole(userRoles)) {
		return <div>Unauthorized</div>
	}

	function validateDate() {
		const { dob_day, dob_month, dob_year } = methods.getValues()
		const birthDate = new Date(dob_year, dob_month - 1, dob_day)
		const isValidDate =
			birthDate.getFullYear() === dob_year && birthDate.getMonth() + 1 === dob_month && birthDate.getDate() === dob_day

		if (!isValidDate) {
			methods.setError('dob_day', { type: 'manual', message: 'Invalid date' })
			return false
		}

		return true
	}

	function validateAge() {
		const { dob_year, dob_month, dob_day } = methods.getValues()
		const today = new Date()
		const birthDate = new Date(dob_year, dob_month - 1, dob_day)
		let age = today.getFullYear() - birthDate.getFullYear()
		const m = today.getMonth() - birthDate.getMonth()

		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--
		}

		if (age < 18) {
			methods.setError('dob_day', { type: 'manual', message: 'You must be at least 18 years old.' })
			return false
		}

		return true
	}

	function onSubmit() {
		const newData = methods.getValues()
		createUserMutation.mutate(newData)
	}

	async function handleNext() {
		const isFieldsValid = await methods.trigger()
		if (!isFieldsValid) return

		const validations: { [step: number]: () => boolean } = {
			0: () => validateDate() && validateAge(),
		}

		const isValid = validations[currentStep] ? validations[currentStep]() : true
		if (!isValid) return

		if (currentStep === steps.length - 1) {
			await methods.handleSubmit(onSubmit)()
		} else {
			setCurrentStep(currentStep + 1)
		}
	}

	return (
		<Box sx={{ padding: 2, maxWidth: 600, width: '100%', mx: 'auto', display: 'flex', minHeight: '0px' }}>
			<Container>
				<ContentCard sx={{ display: 'flex', flexDirection: 'column', maxHeight: '100%' }}>
					<RegistrationHeader step={steps.find((step) => step.step === currentStep)!} />
					<FormProvider {...methods}>
						<Box
							component="form"
							onSubmit={methods.handleSubmit(onSubmit)}
							noValidate
							sx={{
								textAlign: 'center',
								flex: 1,
								minHeight: '0px',
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Box sx={{ overflow: 'auto', flex: 1, display: 'flex' }}>
								<StepRenderer currentStep={currentStep} />
							</Box>
							<div>
								<BtnLg
									onClick={handleNext}
									loading={createUserMutation.isPending}
									sx={{ mt: 1, mb: 2 }}
								>
									CONTINUE
								</BtnLg>
								{currentStep > 0 && (
									<Link
										component="button"
										onClick={() => setCurrentStep(currentStep - 1)}
										sx={{ color: 'black', fontWeight: 400, fontSize: '13px' }}
									>
										BACK
									</Link>
								)}
							</div>
						</Box>
					</FormProvider>
				</ContentCard>
			</Container>
		</Box>
	)
}
