import { Box, Paper, Typography, Link } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { useUser } from '../react-query/hooks/use-user.ts'
import { Loading } from '../components/Loading'
import Alert from '../assets/images/error/alert.svg'
import { AlertPage } from '../components/error/AlertPage'
import { DashboardLayout } from 'src/components/dashboard-components/dashboard-layout.tsx'

export function LinkAccountErrorPage() {
	const { isLoading: queryRunning } = useUser()
	const navigate = useNavigate()

	if (queryRunning) {
		return <Loading />
	}
	return (
		<DashboardLayout>
			<Box
				data-testid="LinkAccountSuccessPage"
				display="flex"
				flexDirection="column"
				alignItems="center"
				sx={{ px: '17px' }}
			>
				<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto' }}>
					<Box data-testid="ErrorPage">
						<AlertPage
							top={{ margin: '10px 17px 0px' }}
							image={{ src: Alert, margin: '0 0px 50px' }}
							imageWidth={100}
							content={
								<Box sx={{ textAlign: 'center' }}>
									<Typography
										variant="h6"
										color="error"
										fontWeight="bolder"
										gutterBottom
									>
										Not an eligible account
									</Typography>
									<Typography variant="body2">Please ensure you are using a checking account and try again</Typography>
									<Typography
										variant="body2"
										sx={{ margin: '25px' }}
									>
										Need help? <Link href="mailto:support@payboticfinancial.com">Contact Us</Link>
									</Typography>
								</Box>
							}
							buttom={{ text: 'Return', onClick: () => navigate('/link-account') }}
						/>
					</Box>
				</Paper>
			</Box>
		</DashboardLayout>
	)
}
