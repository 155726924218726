import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { User } from '../../models/user.models.ts'
import { KyInstance, HTTPError } from 'ky'
import { Nullable } from '../../@types/nullable'

async function getUser(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('users/user')
		return response.json<User>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useUser() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()

	return useQuery<Nullable<User>, Error, Nullable<User>>({
		queryKey: ['user'],
		queryFn: () => {
			return getUser(paymentServiceClient.httpInstance)
		},
		enabled: !isLoading && isAuthenticated,
		staleTime: 300000, // 5 minutes
	})
}
