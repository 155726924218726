import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { User } from '../../models/user.models.ts'
import { KyInstance } from 'ky'
import { Nullable } from '../../@types/nullable'

async function getDeactivatedUser(httpInstance: KyInstance) {
	const response = await httpInstance.get('users/user/deactivated')
	return response.json<User>()
}

export function useDeactivatedUser() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<User>, Error, Nullable<User>>({
		queryKey: ['userDeactivated'],
		queryFn: () => getDeactivatedUser(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated,
		staleTime: 1000, // 1 second
	})
}
