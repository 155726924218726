import { DashboardLayout } from '../components/dashboard-components/dashboard-layout.tsx'
import { Button, Skeleton, Typography } from '@mui/material'
import { RecentTransactions } from '../components/dashboard-components/recent-transactions.tsx'
import { Link } from 'react-router-dom'
import { usePurchasePower } from '../react-query/hooks/use-purchase-power.ts'
import { useEffect } from 'react'
import { useAlert } from '../lib/useAlert.tsx'
import { useUser } from '../react-query/hooks/use-user.ts'
import { PurchasePower } from '../components/purchase-power.tsx'

export function ProfilePage() {
	const { data: dbUser, isLoading: queryRunning } = useUser()
	const { triggerSnackbar } = useAlert()
	const { data: purchasePower, isLoading, error } = usePurchasePower()

	useEffect(() => {
		if (error) {
			triggerSnackbar('Failed to fetch purchase power')
		}
	}, [error, triggerSnackbar])

	return (
		<DashboardLayout>
			<Typography
				variant="h4"
				fontWeight="bold"
				sx={{ color: 'white', mb: 2, fontSize: '17px', textAlign: 'center' }}
			>
				Profile
			</Typography>
			{isLoading || queryRunning ? (
				<Skeleton
					variant="rounded"
					width={'100%'}
					height={'177px'}
				/>
			) : (
				<PurchasePower
					purchasePower={purchasePower}
					dbUser={dbUser}
				/>
			)}

			<Link to={'/dashboard/profile/manage-linked-accounts'}>
				<Button
					variant="contained"
					color="secondary"
					size="large"
					fullWidth={true}
					sx={{
						mt: 2,
						mb: 4,
					}}
				>
					Manage Linked Accounts
				</Button>
			</Link>
			<RecentTransactions />
		</DashboardLayout>
	)
}
