import { useRouteError } from 'react-router-dom'
import { ErrorPage } from './error-page.tsx'

export const ErrorUsage = () => {
	const error = useRouteError()

	// Todo: Implement logging
	console.log(error)

	// Todo: Implement exception page
	return <ErrorPage />
}
