import { createContext, useContext, ReactNode, useEffect, useMemo, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { PaymentServiceClient } from '../payment-service-client.ts'

type PaymentServiceClientContextType = {
	paymentServiceClient: PaymentServiceClient
	isLoading: boolean
	isAuthenticated: boolean
}

type PaymentServiceProviderProps = {
	children: ReactNode
}

const PaymentServiceProviderContext = createContext<PaymentServiceClientContextType | undefined>(undefined)

export function PaymentServiceProvider({ children }: PaymentServiceProviderProps) {
	const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0()

	const paymentServiceClientRef = useRef<PaymentServiceClient | null>(null)

	if (!paymentServiceClientRef.current) {
		paymentServiceClientRef.current = PaymentServiceClient.getInstance(getAccessTokenSilently)
	}

	useEffect(() => {
		if (paymentServiceClientRef.current) {
			paymentServiceClientRef.current.setTokenGenerator(getAccessTokenSilently)
		}
	}, [getAccessTokenSilently])

	const contextValue = useMemo(
		() => ({
			paymentServiceClient: paymentServiceClientRef.current!,
			isLoading,
			isAuthenticated,
		}),
		[isLoading, isAuthenticated],
	)

	return (
		<PaymentServiceProviderContext.Provider value={contextValue}>{children}</PaymentServiceProviderContext.Provider>
	)
}

export function usePaymentService() {
	const context = useContext(PaymentServiceProviderContext)
	if (context === undefined) {
		throw new Error('usePaymentService must be used within a PaymentServiceProvider')
	}
	return context
}
