import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Link, Paper, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loading } from 'src/components/Loading'
import RedirectPage from 'src/modules/redirect/pages/redirect-page'
import { useActivateAccount } from 'src/react-query/hooks/use-activate-account'
import { useDeactivatedUser } from 'src/react-query/hooks/use-get-deactivated-user'
import { getLogoutOptions } from 'src/utils/logout-utils'

export function ReEnableUserPage() {
	const navigate = useNavigate()

	const { user, isLoading, logout } = useAuth0()
	const activateAccountMutation = useActivateAccount()
	const { data: deactivatedUser, isLoading: isLoadingDeactivatedUser } = useDeactivatedUser()

	useEffect(() => {
		if (!activateAccountMutation.isPending && activateAccountMutation.isSuccess) {
			navigate('/email-verification')
		}
	}, [navigate, activateAccountMutation.isPending, activateAccountMutation.isSuccess])

	if (isLoading || isLoadingDeactivatedUser) {
		return <Loading />
	}

	if (!isLoadingDeactivatedUser && !deactivatedUser) {
		return <RedirectPage />
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			sx={{ padding: 2 }}
		>
			<Paper sx={{ p: 4, maxWidth: 600, width: '100%', mx: 'auto', mt: 2 }}>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					sx={{ padding: '20px' }}
				>
					<Typography
						variant="h6"
						sx={{ fontWeight: 'bold' }}
						gutterBottom
					>
						Account disabled
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						align="center"
					>
						Your account{' '}
						<Box
							component="span"
							sx={{ textDecoration: 'underline', color: 'primary.main' }}
						>
							{user?.email + ' '}
						</Box>
						is currently disabled.
					</Typography>
					<Typography
						variant="body2"
						color="textSecondary"
						align="center"
						gutterBottom
					>
						Just click on the button below in order to re-enable it.
					</Typography>
					<Button
						variant="contained"
						color="warning"
						sx={{ marginTop: '10px' }}
						onClick={() => activateAccountMutation.mutate()}
						disabled={activateAccountMutation.isPending}
					>
						RE-ENABLE ACCOUNT
					</Button>
					<Button
						variant="contained"
						sx={{ marginTop: '10px' }}
						onClick={() => logout(getLogoutOptions())}
						disabled={activateAccountMutation.isPending}
					>
						GO TO LOGIN PAGE
					</Button>
					<Typography
						variant="body2"
						sx={{ marginTop: '20px' }}
						align="center"
					>
						Need help? <Link href="mailto:support@payboticfinancial.com">Contact Us</Link>
					</Typography>
				</Box>
			</Paper>
		</Box>
	)
}
