import { Box, Typography } from '@mui/material'
import { Step } from '../pages/registration-page.tsx'

type Props = {
	step: Step
}
export function RegistrationHeader({ step }: Props) {
	const { title, subtitle, step: currentStep } = step
	return (
		<Box sx={{ textAlign: 'center' }}>
			<Typography
				variant="h5"
				fontWeight="700"
				fontSize="20px"
			>
				Registration
			</Typography>
			<Typography
				variant="h5"
				fontWeight="400"
				fontSize="16px"
			>
				{`Step ${currentStep + 1} of 3`}
			</Typography>
			<Typography
				variant="h5"
				fontWeight="700"
				fontSize="20px"
				marginTop="25px"
			>
				{title}
			</Typography>
			<Typography
				variant="body1"
				fontWeight="400"
				fontSize="13px"
			>
				{subtitle}
			</Typography>
		</Box>
	)
}
