import { motion } from 'framer-motion'

import { ReactNode } from 'react'

type Props = {
	children: ReactNode
}

const rotateVariants = {
	initial: { rotate: 0 },
	animate: { rotate: 360 },
}

const buttonTransition = {
	duration: 1,
	ease: 'linear',
	repeat: 0,
}

export const Effect03 = ({ children }: Props) => {
	return (
		<motion.div
			variants={rotateVariants}
			initial="initial"
			animate="animate"
			transition={buttonTransition}
		>
			{children}
		</motion.div>
	)
}
