import { Card } from '@mui/material'
import { useAppStore } from '../../../../store/appStore.ts'
import { useNavigate } from 'react-router-dom'
import { convertToCents } from '../../../../utils/money-utils.ts'
import { Loading } from '../../../../components/Loading'
import { COLOR } from '../../../../layout/constants.ts'
import { ReadQrCode } from '../../../../components/ReadQrcode'
import { usePayment } from '../../../../react-query/hooks/use-payment.ts'
import { useCallback, useEffect, useState } from 'react'
import { HTTPError } from 'ky'
import { MerchantLayout } from '../../layout/MerchantLayout.tsx'
import { BtnLg } from 'src/components/btn-lg.tsx'

export function MerchantCheckoutReadqrPage() {
	const { isSuccess, error, data, mutate } = usePayment()
	const purchase = useAppStore((state) => state.purchase)
	const setPurchaseResult = useAppStore((state) => state.setPurchaseResult)
	const setPurchaseError = useAppStore((state) => state.setPurchaseError)
	const setPurchase = useAppStore((state) => state.setPurchase)
	const [openCamera, setOpenCamera] = useState(true)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	const handleError = useCallback(
		async (err: HTTPError | null) => {
			const res = await err?.response?.json()
			setLoading(false)
			setOpenCamera(false)
			setPurchaseError(res.message)
			navigate('/merchant/checkout/fail')
		},
		[navigate, setPurchaseError],
	)

	useEffect(() => {
		if (isSuccess) {
			setLoading(false)
			setOpenCamera(false)

			if (data) {
				setPurchase(null)
				setPurchaseResult(data)
				navigate('/merchant/checkout/success')
			} else {
				navigate('/merchant/checkout/fail')
			}
		}

		if (error) {
			handleError(error as HTTPError)
		}
	}, [data, error, handleError, isSuccess, navigate, setPurchase, setPurchaseResult])

	const handlePayment = (data: string) => {
		if (!purchase) return

		setLoading(true)
		setOpenCamera(false)
		const purchaseId = data.split('/').pop()
		mutate({
			purchase: purchaseId ?? '',
			amount: convertToCents(purchase?.price),
			notes: purchase?.notes,
			pos_order_id: purchase?.posOrderId,
		})
	}

	// this is needed to mimic the QR Code read in the E2E test
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(window as any).onScan = handlePayment

	if (loading) {
		return <Loading text="Wait a moment..." />
	}

	return (
		<MerchantLayout
			title={'Checkout'}
			style={{ maxWidth: 480 }}
		>
			{openCamera && (
				<Card
					sx={{
						margin: '0 auto',
						background: COLOR.white,
						borderRadius: 1,
						width: '90vw',
						maxWidth: '100%',
						padding: '20px',
						marginBottom: '20px',
					}}
				>
					{openCamera && <ReadQrCode callback={handlePayment} />}
				</Card>
			)}
			<BtnLg
				onClick={() => navigate(-1)}
				loading={false}
				sx={{ mt: 1, mb: 2 }}
			>
				Back
			</BtnLg>
		</MerchantLayout>
	)
}
