import { Controller, useFormContext } from 'react-hook-form'
import { z } from 'zod'

import { PartialEditUserAddress } from '../../models/user.models.ts'
import { AddressSchema } from 'src/utils/validations.tsx'
import { usStates } from '../../utils/us-states.ts'
import { Grid, TextField } from '@mui/material'
import { MyTextField } from '../form-components/my-text-field.tsx'
import { MyAutocompleteField } from '../form-components/my-autocomplete-field.tsx'

export const UserAddressSchema = z.object({
	address: AddressSchema.pick({
		street_one: true,
		street_two: true,
		city: true,
		state: true,
		postal_code: true,
	}),
})

export function EditAddress() {
	const {
		control,
		formState: { errors },
	} = useFormContext<PartialEditUserAddress>()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<MyTextField
					name="address.street_one"
					label="Street Address"
					error={errors.address?.street_one}
					control={control}
					defaultValue=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<MyTextField
					name="address.street_two"
					label="Address Line 2"
					error={errors.address?.street_two}
					control={control}
					defaultValue=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
			>
				<MyTextField
					name="address.city"
					label="City"
					error={errors.address?.city}
					control={control}
					defaultValue=""
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<MyAutocompleteField
					name="address.state"
					label="State/Territory"
					error={errors.address?.state}
					control={control}
					defaultValue=""
					options={usStates}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={6}
			>
				<Controller
					name="address.postal_code"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextField
							label="ZIP Code"
							variant="outlined"
							value={value}
							onChange={(e) => {
								e.preventDefault()
								if (e.target.value.length <= 5) {
									onChange(e.target.value)
								}
							}}
							margin="dense"
							size="small"
							error={!!error}
							helperText={error ? error.message : null}
							inputProps={{ maxLength: 11 }}
							fullWidth
						/>
					)}
				/>
			</Grid>
		</Grid>
	)
}
