import { motion } from 'framer-motion'

import { ReactNode } from 'react'

type Props = {
	children: ReactNode
}

export const Effect02 = ({ children }: Props) => {
	return (
		<motion.div
			className="box"
			initial={{ opacity: 0, scale: 0.5 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{
				duration: 2,
				delay: 0.5,
				ease: [0, 0.71, 0.2, 1.01],
			}}
		>
			{children}
		</motion.div>
	)
}
