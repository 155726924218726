import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'

import BackgroundLanding from '../../../assets/images/background-landing.svg'
import BackgroundGray from '../../../assets/images/background-gray.svg'
import BackgroundImage from '../../../assets/images/background.svg'
import { useMemo } from 'react'

export const useBackgroundImage = () => {
	const { isAuthenticated } = useAuth0()
	const location = useLocation()

	return useMemo(() => {
		if (location.pathname.includes('/exception')) {
			return BackgroundGray
		}

		if (!isAuthenticated) return BackgroundLanding

		if (
			location.pathname.includes('/dashboard/settings') ||
			location.pathname.includes('/payment/confirmation') ||
			location.pathname.includes('/account-under-review')
		) {
			return BackgroundGray
		}

		return BackgroundImage
	}, [isAuthenticated, location.pathname])
}
