import { usePaymentService } from '../services/providers/payment-service-provider.tsx'
import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'
import { Nullable } from '../../@types/nullable'
import { Facility } from '../../@types/facility'

async function getFacilities(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('businesses/facilities')
		return response.json<Facility[]>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}
export function useFacilities() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<Facility[]>, Error, Nullable<Facility[]>>({
		queryKey: ['facilities'],
		queryFn: () => getFacilities(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated,
	})
}
