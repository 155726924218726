import {
	Box,
	Collapse,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { TableRowsLoader } from './TableLoader.tsx'
import { useState } from 'react'

type Column = {
	label: string
	align?: 'left' | 'center' | 'right'
	dataIndex: string
	render?: (value: string | number) => React.ReactNode | string
}

type Row = {
	[key: string]: string | number
}

type DataTableProps<T extends Row> = {
	header?: string
	data: T[]
	columns: Column[]
	loading?: boolean
	expandable?: (row: T) => React.ReactNode
}

export function DataTable<T extends Row>({ header, data, columns, loading, expandable }: DataTableProps<T>) {
	const [expandedRow, setExpandedRow] = useState<number>()

	return (
		<TableContainer
			sx={{ mt: 2 }}
			component={Paper}
		>
			{header && (
				<Box sx={{ px: 2, py: 3 }}>
					<Typography
						sx={{
							fontSize: 16,
							fontWeight: 600,
						}}
					>
						{header}
					</Typography>
				</Box>
			)}
			<Table
				sx={{ minWidth: 650 }}
				data-testid="table-component"
				aria-label="simple table"
			>
				<TableHead>
					<TableRow sx={{ background: '#CDD7E1' }}>
						{columns.map((column) => (
							<TableCell
								align={column.align || 'left'}
								key={column.label}
							>
								{column.label}
							</TableCell>
						))}
						{expandable && <TableCell sx={{ p: 0 }} />}
					</TableRow>
				</TableHead>
				<TableBody>
					{loading ? (
						<TableRowsLoader
							colsNum={columns.length}
							rowsNum={5}
						/>
					) : (
						data.map((row, index) => (
							<>
								<TableRow
									key={index}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									{columns.map((column) => {
										if (column.render) {
											return (
												<TableCell align={column.align || 'left'}>
													{column.render(row[column.dataIndex] as string)}
												</TableCell>
											)
										}

										return <TableCell align={column.align || 'left'}>{row[column.dataIndex]}</TableCell>
									})}
									{expandable && (
										<TableCell align="center">
											<IconButton
												aria-label="expand row"
												size="small"
												onClick={() => setExpandedRow(index === expandedRow ? undefined : index)}
											>
												{index === expandedRow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
											</IconButton>
										</TableCell>
									)}
								</TableRow>
								{expandable && (
									<TableRow>
										<TableCell
											style={{ paddingBottom: 0, paddingTop: 0 }}
											colSpan={6}
										>
											<Collapse in={index === expandedRow}>{expandable(row)}</Collapse>
										</TableCell>
									</TableRow>
								)}
							</>
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
