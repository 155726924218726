import { KyInstance } from 'ky'
import { useQuery } from '@tanstack/react-query'
import { BusinessUser } from '../../merchant/models/business-user.models.ts'
import { usePaymentService } from '../../../react-query/services/providers/payment-service-provider.tsx'

const getBusinessUsers = async (httpInstance: KyInstance, businessId: number) => {
	return await httpInstance.get(`businesses/${businessId}/users`).json<BusinessUser[]>()
}

export const useBusinessUsers = (businessId: number | undefined) => {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<BusinessUser[], Error, BusinessUser[]>({
		queryKey: ['business-users'],
		queryFn: () => {
			if (businessId === undefined) {
				throw new Error('businessId undefined')
			}
			return getBusinessUsers(paymentServiceClient.httpInstance, businessId)
		},
		enabled: !isLoading && isAuthenticated,
	})
}
